import { store } from '@/store.js'
import axios from 'axios'
import { DRFDatatype, DRFEnum, DRFObject, DRFObjectToServer, DRFResponseToObject, IdentityResponseToObject } from './datatypes'
import MIMEType from 'whatwg-mimetype'

var api_backend_url = null


/******************************
 *           BASICS           *
 ******************************/

export function detail_url(url, pk, rest="") {
  return url + pk.toString() + '/' + rest
}

export function null_if_empty(v) {
  if (typeof v === 'string' && v.length == 0) return null
  return v
}

export function get_html_code(e) {
  return e?.response?.status
}

export function err_to_msg(ex, header = null) {
  console.log(ex)

  let response = header
  const exception = Array.isArray(ex) ? ex : [ex]

  for (const e of exception) {
    if (!e) continue

    const html_code = (e.response && e.response.status) ? e.response.status : null

    if (e.response && e.response.data) {
      console.log(e.response.data)
      let err_text = ""

      err_text = JSON.stringify(e.response.data)
      if (err_text.length > 300) {
        err_text = err_text.slice(0, 300)
      }
        
      response += '\n' + err_text
    }
    else if (e.message) {
      response += '\n' + e.message
    }
    else if (typeof e === 'string') {
      response += '\n' + e
    }

    if (html_code) {
      response += ' (' + html_code + ')'
    }
    
    if (e.message && !e.response && !e.throttled) {
      // Log to server - unknown exception
      let text = e.message
      if (e.stack) text += ' | stack: ' + e.stack
      backend_log(text).catch(e => { /**/ })
    }
  }

  return response
}

export function set_api_backend_url(s) {
  api_backend_url = s
}

export function is_authenticated() {
  return !!store.auth_token
}

export async function login(username, password) {
  try {
    if (is_authenticated()) {
      await logout();
    }
  } catch(e) { console.log(e); }
  
  const r = await axios.post(
    api_backend_url + 'login/', 
    {'username': username, 'password': password}
  )
  store.auth_token = r.data.token
  return r.data.token
}

export async function login_from_token(token) {
  store.auth_token = token
}

export async function logout() {
  store.auth_token = ""
}

function preprocess_axios_exceptions(e, e_extra_data) {
  if (e.response && e.response.status && e.response.status != 200 && e.response.headers && e.response.headers['content-type']) {
    const content_type = new MIMEType(e.response.headers['content-type'])
    // Error response, if the content type is not json... fix it...
    if (content_type.type != 'application' || content_type.subtype != 'json') {
      if (e.response.status == 500) {
        // Django sends html responses for unhandled internal server error
        // A custom handler can be set up, but it is difficult to reroute the error only if it has been
        // generated in DRF. This is a bit hackish, but gets the work done.
        e.response.data = "Error interno del servidor. Contacte con un administrador."
      } 
      else if (e.response.status == 404) {
        // Same remarks apply to unhandled 404 errors
        e.response.data = `Página no encontrada: '${e.request?.responseURL}'. Contacte con un administrador.`
      }
      else {
        e.response.data = ""
      }
    }
  }

  if (e_extra_data) {
    e.api_data = e_extra_data
  }

  return e
}

export class Paginator {
  constructor(api_endpoint, params, obj, extra_headers, policy, resp_to_obj_parser = null) {
    this._api_endpoint = api_endpoint
    if (params instanceof URLSearchParams) {
      this._params = new URLSearchParams()
      for (const [key, value] of params) {
        this._params.append(key, value)
      }
    }
    else this._params = {...params}
    this._obj = obj
    this._extra_headers = {...extra_headers}
    this._policy = policy
    this.resp_to_obj_parser = resp_to_obj_parser
    this._wrapper = async (x, p, pn) => await x
    this._cache = null

    this._id = Paginator._id_count++

    // Default policy should be last_requested
    if (!this._policy) {
      this._policy = {
        thread: '_Paginator_' + this._id.toString(),
        policy: "last_requested",
      }
    }

    if (this.resp_to_obj_parser && this._obj.results) {
      this._obj.results = this.resp_to_obj_parser.parse(this._obj.results)
    }
  }

  list() {
    return this._obj.results
  }

  count() {
    return this._obj.count
  }

  page() {
    return this._obj.page
  }

  page_count() {
    return this._obj.page_count
  }

  page_size() {
    return this._obj.page_size
  }

  has_next() {
    return this.page() < this.page_count()
  }

  has_prev() {
    return this.page() > 1
  }

  async next() {
    return await this.jump_to_page(this.page() + 1)
  }

  async prev() {
    return await this.jump_to_page(this.page() - 1)
  }

  async first() {
    this.jump_to_page(1)
  }

  async last() {
    this.jump_to_page('last')
  }

  async jump_to_page(page_num) {
    await this._wrapper(this._jump_to_page(page_num), this.page(), this.page_count())
    return this.list()
  }

  async _jump_to_page(page_num) {
    if (this._params instanceof URLSearchParams) {
      this._params.set('page', page_num)
    }
    else {
      this._params.page = page_num
    }

    const r = await run_api(this._api_endpoint, 'GET', this._params, 'raw', this._extra_headers, this._policy)
    if (r) {
      this._obj = r

      if (this.resp_to_obj_parser && this._obj.results) {
        this._obj.results = this.resp_to_obj_parser.parse(this._obj.results)
      }

      if (this._cache && this._obj) this._cache.save_list(this._obj.results)
    }

    return this
  }

  set_wrapper(new_wrapper) {
    this._wrapper = new_wrapper
  }

  set_policy(new_policy) {
    this._policy = new_policy
  }

  set_cache(cache) {
    this._cache = cache

    if (this._cache && this._obj) this._cache.save_list(this._obj.results)
  }

  static _is_paginated_response(r) {
    return typeof r === 'object' && 'page' in r && 'page_size' in r && 'page_count' in r
  }

  _id_count = 1
}

export class PseudoPaginator {
  constructor(list, page_size) {
    this._list = list
    this._page_size = page_size
    this._page = 1
    this._page_count = Math.ceil(this._list.length / this._page_size)
    this._jump_to_page(1)
  }

  list() {
    return this._current_list
  }

  count() {
    return this._list.length
  }

  page() {
    return this._page
  }

  page_count() {
    return this._page_count
  }

  page_size() {
    return this._page_size
  }

  has_next() {
    return this.page() < this.page_count()
  }

  has_prev() {
    return this.page() > 1
  }

  async next() {
    return await this.jump_to_page(this.page() + 1)
  }

  async prev() {
    return await this.jump_to_page(this.page() - 1)
  }

  async first() {
    this.jump_to_page(1)
  }

  async last() {
    this.jump_to_page('last')
  }

  async jump_to_page(page_num) {
    await this._wrapper(this._jump_to_page(page_num), this.page(), this.page_count())
    return this.list()
  }

  async _jump_to_page(page_num) {
    if (page_num == 'last') page_num = this.page_count()
    if (page_num < 0 || page_num > this.page_count()) {
      this._current_list = []
      return
    }

    const first_index = this.page_size() * (page_num - 1)
    const last_index = Math.min(first_index + this.page_size - 1, this.count() - 1)

    this._current_list = this._list.slice(first_index, last_index + 1)

    return this
  }

  set_wrapper(new_wrapper) {
    this._wrapper = new_wrapper
  }

  set_policy(new_policy) {
    this._policy = new_policy
  }

  set_cache(cache) {
  }

  static _is_paginated_response(r) {
    return typeof r === 'object' && 'page' in r && 'page_size' in r && 'page_count' in r
  }
}


// policy consists on an object with the attributes:
// thread: unique tag for this thread of requests
// policy: one of the following strings
//   'none': requests will be recorded but no action will be taken
//   'single': if a request comes while another one is in course it will fail (exception)
//   'last_requested': requests which are not the last requested one will be dropped (null return)
//   'correlative': requests which arrive after a latter request has arrived will be dropped (null return)

export async function run_api(
  api_endpoint, 
  method = 'GET', 
  params = null, 
  unpaginate = true, 
  e_extra_data = null, 
  policy = null,
  obj_to_server_parser = null,
  resp_to_obj_parser = null
  ) {
  if (run_api.request_id === undefined) {
    run_api.request_id = 0
  }
  run_api.request_id++
  const this_request_id = run_api.request_id

  if (policy) {
    if (run_api.policy_cache === undefined) {
      run_api.policy_cache = {}
    }

    if (!(policy.thread in run_api.policy_cache)) {
      run_api.policy_cache[policy.thread] = {}
    }

    if (policy.policy == 'single' && run_api.policy_cache[policy.thread].active) {
      // A request was already in course... fail
      throw preprocess_axios_exceptions({message: "Demasiadas solicitudes.", throttled: true}, e_extra_data)
    }

    run_api.policy_cache[policy.thread].active = (run_api.policy_cache[policy.thread].active || 0) + 1
    run_api.policy_cache[policy.thread].last_requested = this_request_id
  }


  store.loading++

  let r = null
  let ex = null
  try {
    // Parser args
    if (obj_to_server_parser) {
      params = obj_to_server_parser.parse(params, method == 'GET')
    }

    // Do the request
    r = await run_api_core(api_endpoint, method, params, {}, policy)

    // If paginated, create paginator
    if (Paginator._is_paginated_response(r) && unpaginate != 'raw') {
      // Create paginator
      r = new Paginator(api_endpoint, params, r, {}, policy, resp_to_obj_parser)

      // If we have to unpaginate, do it
      if (unpaginate) {
        let paginator = r
        r = paginator.list()
  
        paginator.set_policy(null)
        while (paginator.has_next()) {
          await paginator.next()
          r = r.concat(paginator.list())
        }
      }
    }
    else {
      if (resp_to_obj_parser) {
        r = resp_to_obj_parser.parse(r)
      }
    }
  }
  catch (e) {
    ex = preprocess_axios_exceptions(e, e_extra_data)
  }

  store.loading--

  if (policy) {
    if (policy.policy == 'last_requested') {
      if (this_request_id < run_api.policy_cache[policy.thread].last_requested) {
        // This is not the last request started
        return null
      }
    } 
    else if (policy.policy == 'correlative') {
      if (this_request_id < run_api.policy_cache[policy.thread].last_arrived) {
        // There is a later request that has already arrived
        return null
      }
    }

    run_api.policy_cache[policy.thread].last_arrived = this_request_id
    run_api.policy_cache[policy.thread].active--
    if (run_api.policy_cache[policy.thread].active == 0) {
      delete run_api.policy_cache[policy.thread]
    }
  }

  if (ex) throw ex
  return r  
}

async function run_api_core(api_endpoint, method = 'GET', params = null, extra_headers = {}, policy = null) {

  if (!api_endpoint.startsWith('http')) {
    api_endpoint = api_backend_url + api_endpoint
  }

  if (method == 'GET') {
    const r = await axios.get(
      api_endpoint, 
      { 
        headers: { 
          'Authorization': 'Token ' + store.auth_token,
          'Accept': 'application/json,text/plain;charset=utf-8',
          ...extra_headers 
        },
        params: params
      }
    )

    return r.data
  }
  else if (method == 'POST') {

    const r = await axios.post(
      api_endpoint, 
      params, 
      {
        headers: { 
          'Authorization': 'Token ' + store.auth_token, 
          'Accept': 'application/json,text/plain;charset=utf-8',
          ...extra_headers 
        },
      }
    )

    return r.data
  }
  else if (method == 'PATCH') {

    const r = await axios.patch(
      api_endpoint, 
      params, 
      {
        headers: { 
          'Authorization': 'Token ' + store.auth_token, 
          'Accept': 'application/json;charset=utf-8',
          ...extra_headers 
        },
      }
    )

    return r.data  
  }
  else if (method == 'PUT') {

    const r = await axios.put(
      api_endpoint, 
      params, 
      {
        headers: { 
          'Authorization': 'Token ' + store.auth_token, 
          'Accept': 'application/json;charset=utf-8',
          ...extra_headers 
        },
      }
    )

    return r.data
  }
  else if (method == 'DELETE') {

    const r = await axios.delete(
      api_endpoint, 
      {
        headers: { 
          'Authorization': 'Token ' + store.auth_token, 
          'Accept': 'application/json;charset=utf-8',
          ...extra_headers 
        },
      }
    )

    return r.data
  }

  throw "Not implemented"
}

export async function helloworld() {
  return await run_api('helloworld/')
}

export async function backend_log(text) {
  return await run_api(
    'log/',
    'POST',
    {
      body: text
    }
  )
}




/******************************
 *        API HELPERS         *
 ******************************/

class APIEnum {
  constructor(data, tag, endpoint_tag) {
    this._data = data
    this._tag = tag
    this._endpoint_tag = endpoint_tag
    this._enum_type = DRFEnum.$({source: endpoint_tag + '.' + tag})
  }

  get_default(type = undefined) {
    const def = this._data.find(x => x?.default)
    const return_type = type === undefined ? this._enum_type : type
    if (def) return new return_type(def.value)
    return undefined
  }

  get_data(v) {
    return this._data.find(x => x?.value === +v)
  }

  get_title(v) {
    return this.get_data(v)?.title
  }

  _populate() {
    for (const x of this._data) {
      this[x.tag] = new this._enum_type(x.value)
    }
  }
}

class APICache {
  constructor(rot_interval = 60000) {
    this.rot_interval = rot_interval
    this.invalidate()
    this._periodic_rotation()
  }

  save_one(obj) {
    this.replace_or_add(obj.id, obj)
  }

  save_list(list) {
    for (const x of list) {
      this.save_one(x)
    }
  }

  check(id) {
    return (id in this._cache[0] || id in this._cache[1] || id in this._cache[2])
  }

  async save_promise(id, p) {
    this.replace_or_add(id, p)
    try {
      const r = await p
      this.replace_or_add(id, r)
      return r
    }
    catch(e) {
      this.delete(id)
      throw e
    }
  }

  replace_or_add(id, obj) {
    if (id in this._cache[1]) this._cache[1][id] = this._copy(obj)
    else if (id in this._cache[2]) this._cache[2][id] = this._copy(obj)
    else this._cache[0][id] = this._copy(obj)
  }

  delete(id) {
    if (id in this._cache[0]) delete this._cache[0][id]
    else if (id in this._cache[1]) delete this._cache[1][id]
    else if (id in this._cache[2]) delete this._cache[2][id]
  }

  async retrieve(id) {
    let n = null
    if (id in this._cache[0]) n = 0
    else if (id in this._cache[1]) n = 1
    else if (id in this._cache[2]) n = 2
    else return null

    // If cache[id] is a promise, it is because there is an
    // ongoing save_promise call, which will store a copy of
    // the object afterwards
    return this._copy(await Promise.resolve(this._cache[n][id]))
  }

  invalidate() {
    this._cache = [{}, {}, {}]
  }

  rotate() {
    this._cache = [{}, this._cache[0], this._cache[1]]
  }

  _copy(obj) {
    // Not exactly a deep copy, we only copy objects, arrays, native types and DRFDatatypes
    if (Array.isArray(obj)) return obj.map(x => this._copy(x))
    if (obj?.constructor === Object) {
      let new_obj = {}
      for (const key in obj) new_obj[key] = this._copy(obj[key])
      return new_obj
    }
    if (DRFDatatype.superclass_of(obj)) return obj.clone()

    return obj
  }

  _periodic_rotation() {
    this.rotate()

    if (this.rot_interval) {
      setTimeout(() => {this._periodic_rotation()}, this.rot_interval)
    }
  }
}

class APIEndpointExecutor {

  constructor(api, endpoint, schema = null, cache = null) {
    this.api = api
    this.endpoint = endpoint
    this.schema = schema
    this.cache = cache
  }

  async list_all(args={}, policy = null) {
    const obj_to_server_parser = new DRFObjectToServer()
    const resp_to_obj_parser = this.schema ? new DRFResponseToObject([this.schema]) : new IdentityResponseToObject()

    const r = await run_api(
      this.endpoint,
      'GET',
      args,
      true,
      { action: 'list_all', endpoint: this.endpoint, args: args },
      policy,
      obj_to_server_parser,
      null
    )

    if (this.cache && r) this.cache.save_list(r)
    return resp_to_obj_parser.parse(r)
  }

  async list(args={}, policy = null) {
    const obj_to_server_parser = new DRFObjectToServer()
    const resp_to_obj_parser = this.schema ? new DRFResponseToObject([this.schema]) : new IdentityResponseToObject()

    const r = await run_api(
      this.endpoint,
      'GET',
      args,
      false,
      { action: 'list', endpoint: this.endpoint, args: args },
      policy,
      obj_to_server_parser,
      resp_to_obj_parser
    )

    if (r) r.set_cache(this._cache)

    return r
  }

  async get(id, args={}, policy = null) {
    const obj_to_server_parser = new DRFObjectToServer()
    const resp_to_obj_parser = this.schema ? new DRFResponseToObject(this.schema) : new IdentityResponseToObject()

    let r = null
    if (this.cache && Object.keys(args).length == 0 && this.cache.check(id)) {
      return resp_to_obj_parser.parse(await this.cache.retrieve(id))
    }

    const p = run_api(
      detail_url(this.endpoint, id),
      'GET',
      args,
      true,
      { action: 'retrieve', id: id, endpoint: this.endpoint, args: args },
      policy,
      obj_to_server_parser,
      null
    )

    if (this.cache && p) return resp_to_obj_parser.parse(await this.cache.save_promise(id, p))
    else return resp_to_obj_parser.parse(await p)
  }

  async create(args={}, policy = null) {
    const obj_to_server_parser = new DRFObjectToServer()
    const resp_to_obj_parser = this.schema ? new DRFResponseToObject(this.schema) : new IdentityResponseToObject()

    const r = await run_api(
      this.endpoint,
      'POST',
      args,
      true,
      { action: 'create', endpoint: this.endpoint, args: args },
      policy,
      obj_to_server_parser,
      resp_to_obj_parser
    )

    if (this.api) this.api.invalidate_cache()
    return r
  }

  async update(id, args={}, partial=true, policy = null) {
    const obj_to_server_parser = new DRFObjectToServer()
    const resp_to_obj_parser = this.schema ? new DRFResponseToObject(this.schema) : new IdentityResponseToObject()

    const r = await run_api(
      detail_url(this.endpoint, id),
      partial ? 'PATCH' : 'PUT',
      args,
      true,
      { action: 'update', id: id, endpoint: this.endpoint, args: args, partial: partial },
      policy,
      obj_to_server_parser,
      resp_to_obj_parser
    )

    if (this.api) this.api.invalidate_cache()
    return r
  }

  async delete(id, policy = null) {
    const r = await run_api(
      detail_url(this.endpoint, id),
      'DELETE',
      {},
      true,
      { action: 'delete', id:id, endpoint: this.endpoint },
      policy
    )

    if (this.api) this.api.invalidate_cache()
    return r
  }
}

class APIActionExecutor {
  constructor(api, endpoint, endpoint_detail, method, returns) {
    this.api = api
    this.endpoint = endpoint
    this.endpoint_detail = endpoint_detail
    this.method = method
    this.returns = returns
  }

  async do_detail(id, args={}, policy = null) {
    this.retrieve_schema()
    const obj_to_server_parser = new DRFObjectToServer()
    const resp_to_obj_parser = this.schema ? new DRFResponseToObject(this.schema) : new IdentityResponseToObject()

    const r = await run_api(
      detail_url(this.endpoint, id, this.endpoint_detail),
      this.method,
      args,
      false,
      { action: 'action_detail', method: this.method, id: id, endpoint: detail_url(this.endpoint, '%', this.endpoint_detail), args: args },
      policy,
      obj_to_server_parser,
      resp_to_obj_parser
    )

    if (this.api) this.api.invalidate_cache()
    return r
  }

  async do_detail_all(id, args={}, policy = null) {
    this.retrieve_schema()
    const obj_to_server_parser = new DRFObjectToServer()
    const resp_to_obj_parser = this.schema ? new DRFResponseToObject(this.schema) : new IdentityResponseToObject()

    const r = await run_api(
      detail_url(this.endpoint, id, this.endpoint_detail),
      this.method,
      args,
      true,
      { action: 'action_detail_all', method: this.method, id: id, endpoint: detail_url(this.endpoint, '%', this.endpoint_detail), args: args },
      policy,
      obj_to_server_parser,
      resp_to_obj_parser
    )

    if (this.api) this.api.invalidate_cache()
    return r
  }

  async do(args={}, policy = null) {
    this.retrieve_schema()
    const obj_to_server_parser = new DRFObjectToServer()
    const resp_to_obj_parser = this.schema ? new DRFResponseToObject(this.schema) : new IdentityResponseToObject()

    const r = await run_api(
      this.endpoint + this.endpoint_detail,
      this.method,
      args,
      false,
      { action: 'action', method: this.method, endpoint: this.endpoint + this.endpoint_detail, args: args },
      policy,
      obj_to_server_parser,
      resp_to_obj_parser
    )

    if (this.api) this.api.invalidate_cache()
    return r
  }

  async do_all(args={}, policy = null) {
    this.retrieve_schema()
    const obj_to_server_parser = new DRFObjectToServer()
    const resp_to_obj_parser = this.schema ? new DRFResponseToObject(this.schema) : new IdentityResponseToObject()

    const r = await run_api(
      this.endpoint + this.endpoint_detail,
      this.method,
      args,
      true,
      { action: 'action_all', method: this.method, endpoint: this.endpoint + this.endpoint_detail, args: args },
      policy,
      obj_to_server_parser,
      resp_to_obj_parser
    )

    if (this.api) this.api.invalidate_cache()
    return r
  }

  retrieve_schema() {
    if (this.schema !== undefined) return
    if (!this.returns) return

    if (this.returns.schema) {
      this.schema = this.returns.schema
      return
    }

    if (this.returns.resource && this.api) {
      this.schema = this.api[this.returns.resource].schema
      if (this.returns.many) this.schema = [this.schema]
    }
  }
}


class APIEndpoint {
  
  constructor(
    api,
    tag,
    resource_id,
    endpoint, 
    extra = {},
    enums = [],
    capabilities='all',
    actions = [],
    schema = null,
    cache = false,
    cache_rot = 60000) {

    this.api = api
    this.tag = tag
    this.resource_id = resource_id
    this.rid = resource_id
    this.endpoint = endpoint
    this.capabilities = capabilities
    this.schema = schema//this._attach_api_to_schema(schema, api)
    this.cache = cache
    this._cache = new APICache(cache_rot)

    for (var attrname in extra) { this[attrname] = extra[attrname] }

    this.executor = new APIEndpointExecutor(api, endpoint, this.schema, this.cache ? this._cache : null)

    if (capabilities == 'all' || capabilities.includes('list') || capabilities.includes('list_unpaginated')) {
      this.list_all = async function(args={}, policy=null) { return this.executor.list_all(args, policy) }
    }
  
    if (capabilities == 'all' || capabilities.includes('list') || capabilities.includes('list_paginated')) {
      this.list = async function(args={}, policy=null) { return this.executor.list(args, policy) }
    }
  
    if (capabilities == 'all' || capabilities.includes('retrieve') || capabilities.includes('get')) {
      this.get = async function(id, args={}, policy=null) { return this.executor.get(+id, args, policy) }
    }
  
    if (capabilities == 'all' || capabilities.includes('create')) {
      this.create = async function(args={}, policy=null) { return this.executor.create(args, policy) }
    }
  
    if (capabilities == 'all' || capabilities.includes('update')) {
      this.update = async function(id, args={}, partial=true, policy=null) { return this.executor.update(+id, args, partial, policy) }
    }
  
    if (capabilities == 'all' || capabilities.includes('delete')) {
      this.delete = async function(id, policy=null) { return this.executor.delete(+id, policy) }
    }

    this.action_executors = []
    for (const x of actions) {
      let action = null
      let all_action = null
      const endpoint_detail = x.endpoint || x.tag + '/'

      const action_executor = new APIActionExecutor(api, endpoint, endpoint_detail, x.method || 'GET', x.returns)
      this.action_executors.push(action_executor)
  
      if (x.detail) {
        action =  async function(id, args, policy=null) { return action_executor.do_detail(+id, args, policy) }
  
        if (x.unpaginate) { all_action = async function(id, args, policy=null) { return action_executor.do_detail_all(+id, args, policy) } }
      }
      else {
        action = async function(args, policy=null) { return action_executor.do(args, policy) }
  
        if (x.unpaginate) { all_action = async function(args, policy=null) { return action_executor.do_all(args, policy) } }
      }

      action.tag = x.tag
      action.subtag = x.subtag
      action.method = x.method
      action.returns = x.returns
      action.detail = x.detail

      if (all_action) {
        all_action.tag = x.tag
        all_action.subtag = x.subtag
        all_action.method = x.method
        all_action.returns = x.returns
        all_action.detail = x.detail
      }
  
      if (x.subtag) {
        if (!(x.tag in this)) this[x.tag] = {}
        this[x.tag][x.subtag] = action
        if (all_action) this[x.tag][x.subtag + '_all'] = all_action
      }
      else {
        this[x.tag] = action
        if (all_action) this[x.tag + '_all'] = all_action
      }
    }

    for (const x of enums) {
      this[x.tag] = new APIEnum(x.values, x.tag, this.tag)
    }

    if (!this.title) {
      this.title = function(x) { return this.tag + x.id.toString() }
    }
    else if (typeof this.title === 'string') {
      const title = this.title
      this.title = function(x) { return x[title] }
    }
    else {
      const title_f = this.title
      this.title = function(x) { return title_f(x) }
    }

    this._parse_columns()
    this._parse_orderings()
    this._parse_filters()
  }

  invalidate_cache() {
    this._cache.invalidate()
  }

  get_name(plural=false, article=false, determinate=true, capitalized=false) {
    const base = plural ? this.name_plural : this.name

    if (!base) return ""
    if (article && !this.name_gender) return ""

    // En palabras femeninas que comienzan por 'a' tónica esto falla...
    let r = ""
    if (article) {
      if (determinate) {
        if (!plural) {
          if (this.name_gender == 'm') r += 'el '
          else r += 'la '
        }
        else {
          if (this.name_gender == 'm') r += 'los '
          else r += 'las '
        }
      }
      else
      {
        if (!plural) {
          if (this.name_gender == 'm') r += 'un '
          else r += 'una '
        }
        else {
          if (this.name_gender == 'm') r += 'unos '
          else r += 'unas '
        }
      }
    }

    r += base

    if (capitalized) {
      r = r.charAt(0).toUpperCase() + r.slice(1)
    }

    return r
  }

  _parse_columns() {
    if (!this.display_columns) return

    // Add column classes
    if (this.schema) {
      for (let col of this.display_columns) {
        if (col.class && col.class_override) continue
        if (typeof col.value == 'string' && col.value in this.schema) {
          let type = this.schema[col.value]
          if (Array.isArray(type)) type = type[0]
          if (type.annotations?.column_class) {
            if (!col.class) col.class = []
            col.class = col.class.concat(type.annotations.column_class)
          }
        }
      }
    }
  }

  _parse_orderings() {
    // Add default columns
    if (this.display_columns) {
      for (let ordering of this.order_by || []) {
        if (ordering.add_columns) continue

        let value = ordering.value
        if (value.startsWith('-') || value.startsWith('+')) value = value.substring(1)
    
        const c = this.display_columns.find(x =>
          typeof x.value == 'string' && x.value == value
        )

        if (c) ordering.add_columns = [c.id]
      }
    }
  }

  _parse_filters() {
    for (let filter of this.filter_by || []) {
      const query_param = filter.query_param

      // Add default columns
      if (this.display_columns && !filter.add_columns) {

        const c = this.display_columns.find(x =>
          typeof x.value == 'string' && x.value == query_param
        )

        if (c) filter.add_columns = [c.id]
      }

      // Add types
      if (this.schema && !filter.type) {

        if (query_param in this.schema) {
          filter.type = this.schema[query_param]
        }
      }
    }
  }

  get_filter_by_id(id_or_param) {
    if (!this.filter_by) return null
    if (typeof id_or_param == 'string') {
      return this.filter_by.find(x => x.query_param == id_or_param)
    }
    else return this.filter_by.find(x => x.id == id_or_param)
  }

  get_filter_operator_by_id(id_or_symbol, filter = null) {
    if (!this.api?.display?.filter_operators) return null
    if (typeof id_or_symbol == 'string') {
      if (!filter) return null
      return this.get_available_operators(filter).find(x => x.symbol == id_or_symbol)
    }
    else return this.api.display.filter_operators.find(x => x.id == id_or_symbol)
  }

  get_available_operators(filter_or_id) {
    if (!this.api?.display?.filter_operators) return []

    if (typeof filter_or_id == 'number' || typeof filter_or_id == 'string') {
      filter_or_id = this.get_filter_by_id(filter_or_id)
    }
    if (!filter_or_id) return []

    let type = filter_or_id.type
    if (!type) return []
    let type_is_single = true
    if (Array.isArray(type)) {
      type = type[0]
      type_is_single = false
    }    
    
    return this.api.display.filter_operators.filter(op => {
      if ('when_nullable' in op && op.when_nullable != type.get_annotations().null) return false
      if ('when_single' in op && op.when_single != type_is_single) return false
      return op.when.some(filter_type => filter_type.superclass_of(type))
    })
  }

  create_filter_object(filter_or_id, op_or_id, value) {
    if (typeof filter_or_id == 'number' || typeof filter_or_id == 'string') {
      filter_or_id = this.get_filter_by_id(filter_or_id)
    }
    if (typeof op_or_id == 'number' || typeof op_or_id == 'string') {
      op_or_id = this.get_filter_operator_by_id(op_or_id, filter_or_id)
    }
    if (!filter_or_id || !op_or_id) return null
    
    let type = filter_or_id.type
    if (!type) return null
    let type_is_single = true
    if (Array.isArray(type)) {
      type = type[0]
      type_is_single = false
    }

    // Check op is available
    if ('when_nullable' in op_or_id && op_or_id.when_nullable != type.get_annotations().null) return null
    if ('when_single' in op_or_id && op_or_id.when_single != type_is_single) return null
    if (!op_or_id.when.some(filter_type => filter_type.superclass_of(type))) return null

    const arg_count = op_or_id.arg_count ?? 1
    if (arg_count == 2 || arg_count == 0) {
      if (op_or_id.serialize_as) value.serialize_as = op_or_id.serialize_as
    }

    const filter_query_param = filter_or_id.query_param + op_or_id.value
    const filter_query_value = value
    let filter_obj = {}
    filter_obj[filter_query_param] = filter_query_value

    // Create object
    return {
      filter_obj: filter_or_id,
      op_obj: op_or_id,
      value: value,
      title: arg_count == 2
        ? op_or_id.filter_title(filter_or_id.title, ...value)
        : op_or_id.filter_title(filter_or_id.title, value),
      filter: filter_obj
    }
  }

}

export class API {
  constructor(definition) {

    this.object_cache = definition.object_cache || false

    this.all_endpoints = {}
    for (const x of definition.endpoints || []) {
      this[x.tag] = new APIEndpoint(
        this,
        x.tag,
        x.rid || x.tag,
        x.endpoint || x.tag + '/',
        x.extra,
        x.enums,
        x.capabilities ?? 'all',
        x.actions,
        x.schema,
        x.cache ?? this.object_cache ?? false,
        x.cache_rotation ?? definition.cache_rotation ?? 60000
      )

      this.all_endpoints[x.tag] = this[x.tag]
    }

    for (const attrname in definition.extra || {}) { this[attrname] = definition.extra[attrname] }

    DRFEnum.set_api(this)
    DRFObject.set_api(this)

    // Populate enums last, as they create DRFEnum objects which need to access the API
    for (const endpt of definition.endpoints || []) {
      for (const e of endpt.enums || []) {
        this[endpt.tag][e.tag]._populate()
      }
    }
  }

  invalidate_cache() {
    for (const endpoint in this.all_endpoints) {
      this[endpoint].invalidate_cache()
    }
  }
}