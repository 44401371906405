import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { createMetaManager, plugin as metaPlugin } from 'vue-meta'
import { loadFonts } from './plugins/webfontloader'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import router from './router'
import { template_helpers } from './util'
import axios from 'axios'
import { set_api_backend_url, backend_log } from '@/API/api.js'

loadFonts()

var appVersion = "0.4"

// Load config
/* global __webpack_public_path__:writable */
axios.get(__webpack_public_path__ + 'config.json').then(r => {
  const config = r.data

  const app_config = {
    public_path: __webpack_public_path__,
    appVersion: appVersion,
    title: config.long_title,
    ...config
  }

  set_api_backend_url(config.api_backend_url)

  const app = createApp(App)

  app.config.globalProperties = { 
    config: app_config,
    ...template_helpers
  }

  if (process.env.NODE_ENV === 'production') {
    app.config.errorHandler = (e, instance, info) => {
      let text = e.message
      if (e.stack) text += ' | stack: ' + e.stack
      backend_log(text).catch(e => { console.log("ERROR") })
    }
  }

  app
    .use(router)
    .use(vuetify)
    .use(createMetaManager())
    .use(metaPlugin)
    .mount('#app')
})
