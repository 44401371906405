<template>

  <async-dialog 
    :cancelbutton="cancelbutton" 
    :oktext="oktext"
    :canceltext="canceltext"
    :footnote="null"
    :width="width"
    :title="title"
    :capturekeyevent="true"
    :title-detail="titleDetail"
    :persistent="persistent"
    ref="msgbox">
    <v-container class="pa-0">
      <v-row>
        <v-col class="thin-column" v-if="icon">
          <v-icon :icon="icon" class="big-icon" color="primary"></v-icon>
        </v-col>
        <v-col>
          <v-container class="pa-0">
            <v-row>
              <v-col>
                <h3><slot></slot></h3>
              </v-col>
            </v-row>

            <v-row v-for="(i, index) in this.actions" :key="i.id" :class="index == 0 ? 'pt-6' : ''">
              <v-col>
                <v-btn @click="button_click(i)" variant="text" color="primary">
                  {{ i.text }}
                </v-btn>
              </v-col>
            </v-row>

            <v-row v-if="dangerWord">
              <v-col>
                Por favor escribe <strong>{{ dangerWord }}</strong> para confirmar esta acción:
              </v-col>
            </v-row>
            <v-row v-if="dangerWord">
              <v-col>
                <v-text-field
                  variant="outlined"
                  v-model="confirm_text"
                  color="primary"
                  density="compact">
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>

  </async-dialog>

</template>



<style scoped>

.thin-column {
  flex-grow: 0;
}

.big-icon {
  font-size: 5em;
}

</style>

<script>
import AsyncDialog from '@/components/AsyncDialog.vue'

export default {

  components: {
    AsyncDialog,
  },

  props: {
    cancelbutton: { type: Boolean, default: true }, 
    oktext: { default: 'Aceptar' }, 
    canceltext: { default: 'Cancelar' }, 
    title: { required: true },
    titleDetail: {},
    icon: { default: 'mdi-alert-circle-outline' },
    dangerWord: { default: null },
    transform_result: { type: Function, default: v => v },
    actions: { default: [] },
    persistent: { type: Boolean, default: false },
    width: { default: '70%' }
  },

  data() {
    return {
      confirm_text: "",
    }
  },


  methods: {

    async show() {
      this.confirm_text = ""
      const v = await this.$refs.msgbox.show(button => {
        // Cancel close event if danger wrong is activated but has not been typed
        if (this.dangerWord &&
            button == 0 && 
            this.confirm_text.toLowerCase() != this.dangerWord.toLowerCase()) {
          return true
        }
      })

      return this.transform_result(v)
    },

    button_click(action) {
      this.$refs.msgbox.close_ext(action.id)
    }

  }

}
</script>


