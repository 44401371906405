<template>
  <metainfo />
  <v-app class="grey">
    <router-view />

    <v-dialog v-model="stop_app" persistent>
      <v-card>
        <v-card-text>
          Hay una nueva versión de la aplicación disponible. Para continuar por favor presiona F5 o recarga la página.
        </v-card-text>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>

import { backend_log } from '@/API/api.js'
import axios from 'axios'
import { useTheme } from 'vuetify'

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    stop_app: false,
    version_watchdog: null
  }),
  
  beforeMount() {
    // Reroute errors to backend
    window.addEventListener("error", (e) => {
      try {
        let error_msg = e.message
        if (e.stack) { error_msg += ' | stack: ' + e.stack }
        backend_log(error_msg)
      }
      catch { /**/ }
      return true
    })

    // Version watchdog
    this.check_version()
    this.version_watchdog = setInterval(() => {
      this.check_version()
    }, this.config.version_watchdog_interval)

    // Set app theme
    const theme = useTheme()
    if (theme.global.name.value != this.config.default_theme) {
      theme.global.name.value = this.config.default_theme
    }
  },

  beforeUnmount() {
    if (this.version_watchdog) clearInterval(this.version_watchdog)
  },

  metaInfo() {
    return {
      title: this.config.title
    }
  },

  methods: {
    check_version() {
      /* global __webpack_public_path__:writable */
      axios.get(
        __webpack_public_path__ + 'config.json', 
        { 
          headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
          }
        }
      )
        .then(r => {
          if (r.data.appVersion != 'DEBUG' && (
            r.data.appVersion != this.config.appVersion || r.data.configVersion != this.config.configVersion)) {
            this.stop_app = true
          }
        })
        .catch()
    }
  }
}
</script>
