<template>
  <v-snackbar v-model="show_info" timeout="6000" color="info">
    <h3>{{ snack_text_info }}</h3>
    <template v-slot:actions>
      <v-btn variant="text" @click="show_info = false" icon="mdi-close-circle-outline"></v-btn>
    </template>
  </v-snackbar>
  <v-snackbar v-model="show_error" timeout="6000" color="error">
    <h3>{{ snack_text_error }}</h3>
    <template v-slot:actions>
      <v-btn variant="text" @click="show_error = false" icon="mdi-close-circle-outline"></v-btn>
    </template>
  </v-snackbar>
  <v-snackbar v-model="show_warning" timeout="6000" color="warning">
    <h3>{{ snack_text_warning }}</h3>
    <template v-slot:actions>
      <v-btn variant="text" @click="show_warning = false" icon="mdi-close-circle-outline"></v-btn>
    </template>
  </v-snackbar>
  <v-snackbar v-model="show_success" timeout="6000" color="success">
    <h3>{{ snack_text_success }}</h3>
    <template v-slot:actions>
      <v-btn variant="text" @click="show_success = false" icon="mdi-close-circle-outline"></v-btn>
    </template>
  </v-snackbar>

  <msg-box
    :cancelbutton="store.msg_box_queue.length ? store.msg_box_queue[0].cancelbutton : null" 
    :oktext="store.msg_box_queue.length ? store.msg_box_queue[0].oktext : null"
    :canceltext="store.msg_box_queue.length ? store.msg_box_queue[0].canceltext : null"
    :title="store.msg_box_queue.length ? store.msg_box_queue[0].title : ''"
    :icon="store.msg_box_queue.length && store.msg_box_queue[0].icon"
    :danger-word="store.msg_box_queue.length && store.msg_box_queue[0].danger_word"
    :width="msg_box_width"
    ref="msgbox">
    <span style="white-space: pre-line;">{{ store.msg_box_queue.length ? store.msg_box_queue[0].text : "" }}</span>
  </msg-box>

</template>


<script>
import MsgBox from '@/components/MsgBox.vue'
import { store } from '../store.js'

export default {
  components: {
    MsgBox,
  },

  data() {
    return {
      store,
      show_info: false,
      show_error: false,
      show_warning: false,
      show_success: false,
      snack_text_error: "",
      snack_text_warning: "",
      snack_text_info: "",
      snack_text_success: "",
    }
  },

  computed: {
    info_msg() { return store.info_msg },
    error_msg() { return store.error_msg },
    warning_msg() { return store.warning_msg },
    success_msg() { return store.success_msg },
    msg_box_show() { return store.msg_box_show },
    msg_box_width() { 
      if (store.msg_box_queue.length && store.msg_box_queue[0].icon) {
        return "60%"
      }
      return "50%"
    },
    msg_box_queue_length() { return store.msg_box_queue.length },
  },

  watch: {
    info_msg(v) {
      if (v) {
        this.snack_text_info = this.store.info_msg
        this.show_info = true
        this.store.info_msg = ""
      }
    },
    error_msg(v) {
      if (v) {
        this.snack_text_error = this.store.error_msg
        this.show_error = true
        this.store.error_msg = ""
      }
    },
    warning_msg(v) {
      if (v) {
        this.snack_text_warning = this.store.warning_msg
        this.show_warning = true
        this.store.warning_msg = ""
      }
    },
    success_msg(v) {
      if (v) {
        this.snack_text_success = this.store.success_msg
        this.show_success = true
        this.store.success_msg = ""
      }
    },
    msg_box_queue_length(newValue, oldValue) {
      if (oldValue == 0 && newValue > 0) this.launch_msg_box()
    }
  },

  methods: {
    launch_msg_box() {
      setTimeout(() => {
        if (!this.store.msg_box_queue.length) return
        if (this.store.msg_box_show) return

        this.$refs.msgbox.show()
          .then(v => {
            if (this.store.msg_box_queue[0].result) {
              this.store.msg_box_queue[0].result(v)
            }
            this.store.msg_box_queue.shift()
            store.msg_box_show = false 
            this.launch_msg_box()
          })
      }, 300)
    }
  }

}
</script>