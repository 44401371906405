import { reactive } from 'vue'

export const store = reactive({
    auth_token: "",
    loading: 0,
    error_msg: "",
    info_msg: "",
    warning_msg: "",
    success_msg: "",
    msg_box_queue: [],
    msg_box_show: false,
    update: 0,
    update_reason: new Object(),
    member: null,
    user: null,
    app_version: null,
    redirect_to_after_login: null,
    no_scroll_to_top: false,

    workcenters: null,

    // Data for forms which include several
    form_data: {
        prev: '',
        next_page_data: {},
        data: {}
    },
})