import { err_to_msg, get_html_code, Paginator } from "./api"
import { 
  DRFDatatype, 
  DRFString, 
  DRFBoolean, 
  DRFInteger, 
  DRFFloat,
  DRFDecimal,
  DRFDateTime,
  DRFDate,
  DRFTime,
  DRFDuration,
  DRFEnum,
  DRFObject
 } from './datatypes.js'
 import {
  DRFString_Phone,
  DRFDate_Birthday,
  DRFDecimal_Money,
  DRFInteger_Percent,
 }
 from './ext_datatypes.js'


// See documentation.js
export default {

  extra: {
    err_to_msg: err_to_msg,
    get_html_code: get_html_code,
    is_paginator: x => { return x instanceof Paginator },

    display: {
      page_sizes: [
        { title: "5/página", value: 5 },
        { title: "10/página", value: 10 },
        { title: "20/página", value: 20 },
        { title: "30/página", value: 30 },
        { title: "50/página", value: 50 },
        { title: "100/página", value: 100 },
      ],
      default_page_size: 30,
      filter_operators: [
        // text
        { id:  1, title: "Contiene", value: '__icontains', symbol: 'LIKE', filter_title: (f, v) => f + ': contiene ' + v, when: [DRFString] },
        { id:  2, title: "No contiene", value: '__not_icontains', symbol: '!LIKE', filter_title: (f, v) => f + ': no contiene ' + v, when: [DRFString] },
        { id:  3, title: "Igual a", value: '__iexact', symbol: '=', filter_title: (f, v) => f + ': ' + v + '', when: [DRFString] },
        { id:  4, title: "¿Está vacío?", value: '__isempty', symbol: 'EMPTY', filter_title: (f, v) => f + (v ? ': vacío' : ': no vacío'), when: [DRFString], when_nullable: false, options: [{title: "Vacío", value: true}, {title: "No vacío", value: false}] },

        // boolean
        { id: 10, title: "Igual a", value: '', symbol: '=', filter_title: (f, v) => f + (v ? ': sí' : ': no '), when: [DRFBoolean], options: [{title: "Sí", value: true}, {title: "No", value: false}]},

        // numbers: integer, decimal
        { id: 20, title: "Rango", value: '__range', symbol: 'RANGE', filter_title: (f, v1, v2) => f + ': entre ' + v1 + ' y ' + v2, when: [DRFInteger, DRFFloat, DRFDecimal], arg_count: 2, serialize_as: 'csv' },
        { id: 21, title: "Mayor que", value: '__gt', symbol: '>', filter_title: (f, v) => f + ' > ' + v, when: [DRFInteger, DRFFloat, DRFDecimal] },
        { id: 22, title: "Mayor o igual que", value: '__gte', symbol: '>=', filter_title: (f, v) => f + ' ≥ ' + v, when: [DRFInteger, DRFFloat, DRFDecimal] },
        { id: 23, title: "Menor que", value: '__lt', symbol: '<', filter_title: (f, v) => f + ' < ' + v, when: [DRFInteger, DRFFloat, DRFDecimal] },
        { id: 24, title: "Menor o igual que", value: '__lte', symbol: '<=', filter_title: (f, v) => f + ' ≤ ' + v, when: [DRFInteger, DRFFloat, DRFDecimal] },
        { id: 25, title: "Igual a", value: '', symbol: '=', filter_title: (f, v) => f + ': ' + v, when: [DRFInteger, DRFFloat, DRFDecimal] },
        { id: 26, title: "Alguno de", value: '__in', symbol: 'IN', filter_title: (f, v) => f + ': ' + v.join(' o '), when: [DRFInteger, DRFFloat, DRFDecimal], arg_count: 0, serialize_as: 'csv' },
        { id: 27, title: "Ninguno de", value: '__not_in', symbol: '!IN', filter_title: (f, v) => f + (v.length ? ': ni ' : ': no ') + v.join(' ni '), when: [DRFInteger, DRFFloat, DRFDecimal], arg_count: 0, serialize_as: 'csv' },
        { id: 28, title: "Distinto de", value: '__not_exact', symbol: '!=', filter_title: (f, v) => f + ': no ' + v, when: [DRFInteger, DRFFloat, DRFDecimal] },

        // time: datetime, date, time
        { id: 30, title: "Rango", value: '__range', symbol: 'RANGE', filter_title: (f, v1, v2) => f + ': entre ' + v1 + ' y ' + v2, when: [DRFDateTime, DRFDate, DRFTime], arg_count: 2, serialize_as: 'csv' },
        { id: 31, title: "Posterior a", value: '__gte', symbol: '>=', filter_title: (f, v) => f + ': posterior a ' + v, when: [DRFDateTime, DRFDate, DRFTime] },
        { id: 32, title: "Anterior a", value: '__lte', symbol: '<=', filter_title: (f, v) => f + ': anterior a ' + v, when: [DRFDateTime, DRFDate, DRFTime] },
        { id: 33, title: "Igual a", value: '', symbol: '=', filter_title: (f, v) => f + ': ' + v, when: [DRFDate] },
        { id: 34, title: "Alguno de", value: '__in', symbol: 'IN', filter_title: (f, v) => f + ': ' + v.join(' o '), when: [DRFDate], arg_count: 0, serialize_as: 'csv' },
        { id: 35, title: "Ninguno de", value: '__not_in', symbol: '!IN', filter_title: (f, v) => f + (v.length ? ': ni ' : ': no ') + v.join(' ni '), when: [DRFDate], arg_count: 0, serialize_as: 'csv' },
        { id: 36, title: "Distinto de", value: '__not_exact', symbol: '!=', filter_title: (f, v) => f + ': no ' + v, when: [DRFDate] },
        
        // enums
        { id: 40, title: "Igual a", value: '', symbol: '=', filter_title: (f, v) => f + ': ' + v, when: [DRFEnum] },
        { id: 41, title: "Alguno de", value: '__in', symbol: 'IN', filter_title: (f, v) => f + ': ' + v.join(' o '), when: [DRFEnum], arg_count: 0, serialize_as: 'csv' },
        { id: 42, title: "Ninguno de", value: '__not_in', symbol: '!IN', filter_title: (f, v) => f + (v.length ? ': ni ' : ': no ') + v.join(' ni '), when: [DRFEnum], arg_count: 0, serialize_as: 'csv' },
        { id: 43, title: "Distinto de", value: '__not_exact', symbol: '!=', filter_title: (f, v) => f + ': no ' + v, when: [DRFEnum] },

        // foreign single value
        { id: 50, title: "Igual a", value: '', symbol: '=', filter_title: (f, v) => f + ': ' + v, when: [DRFObject], when_single: true },
        { id: 51, title: "Alguno de", value: '__in', symbol: 'IN', filter_title: (f, v) => f + ': ' + v.join(' o '), when: [DRFObject], when_single: true, arg_count: 0, serialize_as: 'csv' },
        { id: 52, title: "Ninguno de", value: '__not_in', symbol: '!IN', filter_title: (f, v) => f + (v.length ? ': ni ' : ': no ') + v.join(' ni '), when: [DRFObject], when_single: true, arg_count: 0, serialize_as: 'csv' },
        { id: 53, title: "Distinto de", value: '__not_exact', symbol: '!=', filter_title: (f, v) => f + ': no ' + v, when: [DRFObject], when_single: true },

        // foreign multiple value
        { id: 60, title: "Alguno de", value: '', symbol: '=', filter_title: (f, v) => f + ': ' + v.join(' o '), when: [DRFObject], when_single: false, arg_count: 0, serialize_as: 'multiple', def_arg_count: 1 },
        { id: 61, title: "Todos", value: '__exact_all', symbol: '==', filter_title: (f, v) => f + ': ' + v.join(' y '), when: [DRFObject], when_single: false, arg_count: 0, serialize_as: 'multiple' },
        { id: 62, title: "Ninguno de", value: '__not_exact', symbol: '!=', filter_title: (f, v) => f + (v.length ? ': ni ' : ': no ') + v.join(' ni '), when: [DRFObject], when_single: false, arg_count: 0, serialize_as: 'multiple', def_arg_count: 1 },

        // nullable
        { id: 70, title: "¿Está vacío?", value: '__isnull', symbol: 'NULL', filter_title: (f, v) => f + (v ? ': vacío' : ': no vacío'), when: [DRFString, DRFBoolean, DRFInteger, DRFFloat, DRFDecimal, DRFDateTime, DRFDate, DRFTime, DRFDuration, DRFEnum], when_nullable: true, options: [{title: "No vacío", value: false}, {title: "Vacío", value: true}] },
        { id: 71, title: "¿Está vacío?", value: '__isnull', symbol: 'NULL', filter_title: (f, v) => f + (v ? ': vacío' : ': no vacío'), when: [DRFObject], when_nullable: true, when_single: true, options: [{title: "No vacío", value: false}, {title: "Vacío", value: true}] },
        { id: 72, title: "¿Está vacío?", value: '__isnull', symbol: 'NULL', filter_title: (f, v) => f + (v ? ': vacío' : ': no vacío'), when: [DRFObject], when_single: false, options: [{title: "No vacío", value: false}, {title: "Vacío", value: true}] },
      ],
      object_load_error_classes: ['text-error', 'text-caption'],
    },
  },

  object_cache: true,
  endpoints: [

    /************ USER ************/
    {
      tag: 'user',
      endpoint: 'user/',
      capabilities: [ ],
      schema: {
        email: DRFString,
        first_name: DRFString,
        last_name: DRFString,
        username: DRFString,

        is_staff: DRFBoolean,
        is_superuser: DRFBoolean,

        userprofile: {
          associated_member: DRFObject.$({resource: 'member_snippets', null: true}),
          can_see_payment_details: DRFBoolean
        }
        
      },
      actions: [
        {
          tag: 'get',
          endpoint: ' ',
          method: 'GET',
          detail: false,
          returns: { resource: 'user' }
        }
      ]
    },


    /************ WORKCENTERS ************/
    {
      tag: 'workcenters',
      endpoint: 'workcenters/',
      capabilities: [ 'list', 'retrieve' ],
      schema: {
        id: DRFObject.$({resource: 'workcenters'}),
      },
      extra: {
        title: 'name',
        name: 'centro', name_plural: 'centros', name_gender: 'm',
        input_filtering_function: x => +x.available,
      },
    },

    /************ MEMBERS ************/
    {
      tag: 'members',
      endpoint: 'members/',
      capabilities: [ 'retrieve' ],
      extra: {
        title: x => x.name + ' ' + x.lastname,
        name: 'alumno', name_plural: 'alumnos', name_gender: 'm'
      },
      enums: [
        {
          tag: 'member_type',
          values: [
            { value: 1, tag: 'student',       title: 'Estudiante' },
            { value: 2, tag: 'teacher',       title: 'Profesor' },
          ]
        },
        {
          tag: 'payment_method',
          values: [
            { value: 1, tag: 'cash',          title: 'Efectivo' },
            { value: 2, tag: 'card',          title: 'Tarjeta' },
            { value: 3, tag: 'transfer',      title: 'Transferencia Bancaria' },
            { value: 6, tag: 'direct_debit',  title: 'Domiciliación Bancaria' },
            { value: 4, tag: 'wallet',        title: 'Monedero Electrónico' },
            { value: 5, tag: 'other',         title: 'Otro' },
            { value: 7, tag: 'coupon',        title: 'Bono/Cupón' }
          ]
        }
      ]
    },

    /************ MEMBER SNIPPETS ************/
    {
      tag: 'member_snippets',
      endpoint: 'member-snippets/',
      capabilities: ['list', 'retrieve'],
      cache_rotation: 1000,
      schema: {
        id: DRFObject.$({resource: 'member_snippets'}),
           
        name: DRFString,
        lastname: DRFString,
        phone: DRFString_Phone,
        email: DRFString.$({null: true}),
        iddoc: DRFString,
        birthday: DRFDate_Birthday.$({null: true, display_age_suffix: 'años'}),

        member_type: DRFEnum.$({source: 'members.member_type'}),

        quicknotes: DRFString,

        subjects: [DRFObject.$({resource: 'subjects'})],
        level: DRFObject.$({null: true, resource: 'levels'}),
        school2: DRFObject.$({null: true, resource: 'schools'}),

        centers: [DRFObject.$({resource: 'workcenters'})],
        groups: [DRFObject.$({resource: 'member_groups'})],
        center_in: DRFObject.$({null: true, resource: 'workcenters'}),
    
        creationdate: DRFDateTime,
        last_edit_date: DRFDateTime,
        last_in_out_date: DRFDateTime.$({null: true}),
      },
      extra: {
        title: x => x.name + ' ' + x.lastname,
        name: 'alumno', name_plural: 'alumnos', name_gender: 'm',
        search: true, 
        default_page_size: 10,
        order_by: [
          { id:  1, title: 'Por defecto: Nombre (a..z)', value: '' },
          { id:  2, title: 'Nombre (a..z)', value: 'name,lastname', add_columns: [1] },
          { id:  3, title: 'Nombre (z..a)', value: '-name,-lastname', add_columns: [1] },
          { id:  4, title: 'Alta sistema (recientes)', value: '-id', add_columns: [8, 9] },
          { id:  5, title: 'Alta sistema (antiguos)', value: 'id', add_columns: [8, 9] },
          { id:  6, title: 'Última modificación (recientes)', value: '-last_edit_date', add_columns: [10, 11] },
          { id:  7, title: 'Última modificación (antiguos)', value: 'last_edit_date', add_columns: [10, 11] },            
          { id:  8, title: 'Última visita (recientes)', value: '-last_in_out_date', add_columns: [12, 13] },
          { id:  9, title: 'Última visita (antiguos)', value: 'last_in_out_date', add_columns: [12, 13] },
          { id: 10, title: 'Edad (jóvenes)', value: '-birthday', add_columns: [7] },
          { id: 11, title: 'Edad (mayores)', value: 'birthday', add_columns: [7] },
        ], 
        filter_by: [
          { id:  1, title: 'Nombre', query_param: 'name' },
          { id:  2, title: 'Apellidos', query_param: 'lastname' },
          { id:  3, title: 'Teléfono', query_param: 'phone' },
          { id:  4, title: 'Email', query_param: 'email' },
          { id:  5, title: 'Notas', query_param: 'quicknotes' },
          { id:  6, title: 'Documento ID', query_param: 'iddoc' },
          { id: 17, title: 'Tipo', query_param: 'member_type' },
          { id:  7, title: 'Fecha nacimiento', query_param: 'birthday'},
          { id:  8, title: 'Alta en el sistema', query_param: 'creationdate', add_columns: [8, 9] },
          { id:  9, title: 'Última modificación', query_param: 'last_edit_date', add_columns: [10, 11] },
          { id: 10, title: 'Última visita', query_param: 'last_in_out_date', add_columns: [12, 13] },
          { id: 11, title: 'Nivel', query_param: 'level' },
          { id: 12, title: 'Asignaturas', query_param: 'subjects' },
          { id: 13, title: 'Colegio', query_param: 'school2' },
          { id: 14, title: 'Centros', query_param: 'centers' },
          { id: 15, title: 'Ahora está en', query_param: 'center_in', add_columns: [18, 13] },
          { id: 16, title: 'Grupos', query_param: 'groups' },
          { id: 17, title: 'Tipo', query_param: 'member_type' },
        ],
        display_columns: [
          { id:  1, title: 'Nombre', value: x => x.name + ' ' + x.lastname },
          { id:  2, title: 'Teléfono', value: 'phone' },
          { id:  3, title: 'Email', value: 'email' },
          { id:  4, title: 'Notas', value: 'quicknotes' },
          { id:  5, title: 'Documento ID', value: 'iddoc', optional: true },
          { id:  6, title: 'Fecha nacimiento', value: 'birthday', optional: true},
          { id:  7, title: 'Edad', value: x => x.birthday ? x.birthday.display_age() : '', optional: true},
          { id:  8, title: 'Alta sistema', value: 'creationdate', optional: true},
          { id:  9, title: 'Tiempo desde alta sistema', value: x => x.creationdate.elapsed(), optional: true},
          { id: 10, title: 'Última modificación', value: 'last_edit_date', optional: true},
          { id: 11, title: 'Tiempo desde última modificación', value: x => x.last_edit_date.elapsed(), optional: true},
          { id: 12, title: 'Última visita', value: 'last_in_out_date', optional: true},
          { id: 13, title: 'Tiempo desde última visita', value: x => x.last_in_out_date.elapsed(), optional: true},
          { id: 14, title: 'Nivel', value: 'level', optional: true },
          { id: 15, title: 'Asignaturas', value: 'subjects', optional: true },
          { id: 16, title: 'Colegio', value: 'school2', optional: true },
          { id: 17, title: 'Centros', value: 'centers', optional: true },
          { id: 18, title: 'Ahora está en', value: 'center_in', optional: true },
          { id: 19, title: 'Grupos', value: 'groups', optional: true },
        ]
      },
      actions: [
        {
          tag: 'autocomplete',
          method: 'GET',
          detail: false,
          returns: {resource: 'member_snippets', many: true},
        }      
      ]
    },

    /************ MEMBER GROUPS ************/
    {
      tag: 'member_groups',
      endpoint: 'member-groups/',
      capabilities: [ 'list', 'retrieve' ],
      schema: {
        id: DRFObject.$({resource: 'member_groups'}),
      },
      extra: {
        title: 'name',
        name: 'grupo', name_plural: 'grupos', name_gender: 'm'
      },
    },

    /************ LEVELS ************/
    {
      tag: 'levels',
      endpoint: 'levels/',
      capabilities: [ 'list', 'retrieve' ],
      schema: {
        id: DRFObject.$({resource: 'levels'}),
      },
      extra: {
        title: 'name',
        name: 'nivel', name_plural: 'niveles', name_gender: 'm'
      },
    },

    /************ SUBJECTS ************/
    {
      tag: 'subjects',
      endpoint: 'subjects/',
      capabilities: [ 'list', 'retrieve' ],
      schema: {
        id: DRFObject.$({resource: 'subjects'}),
      },
      extra: {
        title: x => x.name + ' [' + x.levels__name.join(', ') + ']',
        name: 'asignatura', name_plural: 'asignaturas', name_gender: 'f'
      },
    },

    /************ SCHOOLS ************/
    {
      tag: 'schools',
      endpoint: 'schools/',
      capabilities: 'all',
      schema: {
        id: DRFObject.$({resource: 'schools'}),
      },
      extra: {
        title: 'name',
        name: 'colegio', name_plural: 'colegios', name_gender: 'm'
      },
    },

    /************ THREADS ************/
    {
      tag: 'threads',
      endpoint: 'threads/',
      schema: {
        id: DRFObject.$({resource: 'threads'}),

        subject: DRFString,
        timestamp: DRFDateTime,
        priority: DRFInteger,
        is_pinned: DRFBoolean,
        is_closed: DRFBoolean,
        workcenter: DRFObject.$({resource: 'workcenters'}),
        created_by: DRFObject.$({resource: 'member_snippets'}),
        managed_by: DRFObject.$({resource: 'member_snippets'}),
        students: [DRFObject.$({resource: 'member_snippets'})],
        carbon_copy: [DRFObject.$({resource: 'member_snippets'})],
      },
      extra: {
        title: 'id',
        name: 'hilo', name_plural: 'hilos', name_gender: 'm',
        display_columns: [
          { id:  1, title: 'Fecha', value: 'timestamp' },
          { id:  2, title: 'Asunto', value: 'subject' },
          { id:  3, title: 'Creado por', value: 'created_by' },
          { id:  4, title: 'Asignado a', value: 'managed_by' },
          { id:  5, title: 'Estudiantes', value: 'students' },
          { id:  6, title: 'Centro', value: 'workcenter' },
          { id:  7, title: 'Con copia', value: 'carbon_copy', optional: true },

        ],
        filter_by: [
          { id:  1, title: 'Fecha', query_param: 'timestamp' },
          { id:  2, title: 'Anclado', query_param: 'is_pinned' },
          { id:  3, title: 'Cerrado', query_param: 'is_closed' },
          { id:  4, title: 'Centro', query_param: 'workcenter' },
          { id:  5, title: 'Creado por', query_param: 'created_by' },
          { id:  6, title: 'Asignado a', query_param: 'managed_by' },
          { id:  7, title: 'Estudiantes', query_param: 'students' },
        ]
      },
    },

    /************ POSTS ************/
    {
      tag: 'posts',
      endpoint: 'posts/',
      schema: {
        id: DRFObject.$({resource: 'posts'}),

        thread: DRFObject.$({resource: 'threads'}),
        body: DRFString.$({blank: true}),
        timestamp: DRFDateTime,
        created_by: DRFObject.$({resource: 'member_snippets'})    
      },
      extra: {
        title: 'id',
        name: 'post', name_plural: 'posts', name_gender: 'm',
      },
    },

    /************ PAYMENT RECEIPTS ************/
    {
      tag: 'payment_receipts',
      endpoint: 'app/count2/paymentreceipts/',
      schema: {
        id: DRFObject.$({resource: 'payment_receipts'}),
           
        concept: DRFString,
        comment: DRFString.$({blank: true}),
        amount: DRFDecimal_Money,
        credit_used: DRFDecimal_Money.$({display_prefix: '-'}),
        payer: DRFObject.$({resource: 'members'}),
        date: DRFDate,
        creation_timestamp: DRFDateTime,
        workcenter: DRFObject.$({resource: 'workcenters'}),
        payment_method: DRFEnum.$({source: 'members.payment_method'}),
        managed_by: DRFObject.$({resource: 'members', null: true}),
        receipt_number: DRFString.$({blank: true}),

        accounted_for: DRFBoolean,
      },
      extra: {
        title: x => x.concept,
        name: 'pago', name_plural: 'pagos', name_gender: 'm',
        search: true, 
        default_page_size: 30,
        order_by: [
          { id:  1, title: 'Por defecto: Recientes', value: '' },
          { id:  2, title: 'Importe (mayor)', value: '-amount' },
          { id:  3, title: 'Importe (menor)', value: 'amount' },
          { id:  4, title: 'Fecha (recientes)', value: '-date' },
          { id:  5, title: 'Fecha (antiguos)', value: 'date' },
        ], 
        filter_by: [
          { id:  1, title: 'Fecha', query_param: 'date' },
          { id:  2, title: 'Importe', query_param: 'amount' },
          { id:  8, title: 'Alumno', query_param: 'payer' },
          { id:  4, title: 'Método pago', query_param: 'payment_method' },
          { id:  5, title: 'Fecha creación', query_param: 'creation_timestamp' },
          { id:  6, title: 'Centro', query_param: 'workcenter' },
          { id:  7, title: 'Ya gestionado', query_param: 'accounted_for', add_columns: [] },
        ],
        display_columns: [
          { id:  1, order:  1, title: 'Fecha', value: 'date' },
          { id:  2, order:  2, title: 'Concepto', value: 'concept' },
          { id:  3, order:  3, title: 'Importe', value: 'amount' },
          { id: 12, order:  4, title: 'Crédito', value: 'credit_used' },
          { id: 13, order:  4, title: 'Crédito', value: x => (+x.credit_used != 0) ? x.credit_used.toString() : ''},
          { id:  4, order:  5, title: 'Alumno', value: 'payer' },
          { id:  5, order:  6, title: 'Método pago', value: 'payment_method' },
          { id:  6, order:  7, title: 'Gestionado por', value: 'managed_by' },
          { id:  7, order:  8, title: 'Fecha creación', value: 'creation_timestamp', optional: true },
          { id:  8, order:  9, title: 'Centro', value: 'workcenter' },
          { id:  9, order: 10, title: 'Recibo', value: 'receipt_number', optional: true },
          { id: 10, order: 11, title: 'Comentarios', value: 'comment' },
          { id: 11, order: 12, title: 'Ya gestionado', value: 'accounted_for', optional: true },
        ]
      },
    },


    /************ PRODUCTS ************/
    {
      tag: 'products',
      endpoint: 'app/count2/products/',
      schema: {
        id: DRFObject.$({resource: 'products'}),
           
        name: DRFString,
        product_type: DRFEnum.$({source: 'products.product_type'}),
        student_number: DRFEnum.$({source: 'products.student_number'}),
        visibility: DRFEnum.$({source: 'products.visibility'}),
        parent_product: DRFObject.$({resource: 'products', null: true}),

        start_date: DRFDate.$({null: true}),
        end_date: DRFDate.$({null: true}),

        workcenters: [DRFObject.$({resource: 'workcenters'})],

        options: [DRFObject.$({resource: 'product_options'})],

        disc_student_number: DRFEnum.$({source: 'products.student_number'}),
        disc_min_quantity: DRFDecimal.$({decimal_places: 2, null: true}),
        disc_max_quantity: DRFDecimal.$({decimal_places: 2, null: true}),
        disc_min_amount: DRFDecimal_Money.$({null: true}),
        disc_max_amount: DRFDecimal_Money.$({null: true}),
        disc_is_coupon: DRFBoolean.$({null: true}),
        disc_student_enrolled: DRFBoolean.$({null: true}),
    
        discount: DRFInteger_Percent.$({display_prefix: '-'}),
        discount_fixed: DRFDecimal_Money.$({display_prefix: '-'}),
        discount_reason: DRFString.$({blank: true}),

        category: DRFString.$({blank: true}),
        ui_details_help: DRFString.$({blank: true}),
        ui_options_help: DRFString.$({blank: true}),
      },
      enums: [
        {
          tag: 'product_type',
          values: [
            { value: 0,   tag: 'other',           title: 'Otro' },
            { value: 1,   tag: 'course',          title: 'Curso' },
            { value: 2,   tag: 'light_course',    title: 'Curso sin bonificar' },
            { value: 3,   tag: 'on_demand_class', title: 'Clase particular' },
          ]
        },
        {
          tag: 'student_number',
          values: [
            { value: 0,   tag: 'any',      title: 'Cualquiera' },
            { value: 1,   tag: 'one',      title: 'Individual' },
            { value: 2,   tag: 'two',      title: 'Dúo' },
            { value: 3,   tag: 'three',    title: 'Trío' },
            { value: -1,  tag: 'group',    title: 'Grupo' },
          ]
        },
        {
          tag: 'visibility',
          values: [
            { value: 0,   tag: 'not_available',  title: 'No disponible' },
            { value: 1,   tag: 'available',      title: 'Disponible' },
            { value: 2,   tag: 'hidden',         title: 'Oculto pero disponible' },
          ]
        }
      ],
      actions: [
        {
          tag: 'start_purchase',
          method: 'POST',
          detail: true,
          returns: {resource: 'product_orders'},
        },
        {
          tag: 'duplicate',
          method: 'POST',
          detail: true,
          returns: {resource: 'products'},
        },
        {
          tag: 'get_available_on_demand_classes',
          method: 'GET',
          detail: false,
          returns: {resource: 'product__product_price', many: true},
        },
      ],
      extra: {
        title: x => x.name,
        name: 'producto', name_plural: 'productos', name_gender: 'm',
        search: true, 
        default_page_size: 30,
        order_by: [
          { id:  1, title: 'Por defecto: Cat., Nombre (a..z)', value: '' },
          { id:  2, title: 'Fecha inicio (recientes)', value: '-start_date' },
          { id:  3, title: 'Fecha inicio (antiguos)', value: 'start_date' },
          { id:  4, title: 'Fecha final (recientes)', value: '-end_date' },
          { id:  5, title: 'Fecha final (antiguos)', value: 'end_date' },
          { id:  6, title: 'Fecha creación (recientes)', value: 'id' },
          { id:  7, title: 'Fecha creación (antiguos)', value: '-id' },
          { id:  8, title: 'Nombre (a..z)', value: 'name' },
          { id:  9, title: 'Nombre (z..a)', value: '-name' },
        ],
        filter_by: [
          { id:  1, title: 'Fecha inicio', query_param: 'start_date' },
          { id:  2, title: 'Fecha final', query_param: 'end_date' },
          { id:  3, title: 'Centros', query_param: 'workcenters' },
          { id:  4, title: 'Tipo de producto', query_param: 'product_type' },
          { id:  5, title: 'Visibilidad', query_param: 'visibility' },
        ],
        display_columns: [
          { id: 10, order:  1, title: 'Categoría', value: 'category' },
          { id:  1, order:  2, title: 'Nombre', value: 'name', class: ['font-weight-medium'] },
          { id:  2, order:  3, title: 'Fecha inicio', value: 'start_date' },
          { id:  3, order:  4, title: 'Fecha final', value: 'end_date' },
          { id:  4, order:  5, title: 'Centros', value: 'workcenters', optional: true },
          { id:  6, order:  6, title: 'Visibilidad', value: 'visibility', optional: true },
          { id: 10, order:  7, title: 'Número alumnos', value: 'student_number', optional: true },
          { id:  7, order:  8, title: 'Tipo de producto', value: 'product_type', optional: true },
          { id:  8, order:  9, title: 'Producto matriz', value: 'parent_product', optional: true },
          { id:  9, order: 10, title: 'Opciones', value: 'options', optional: true },
        ]
      },
    },


    /************ PRODUCT -> PRODUCT PRICE ************/
    {
      tag: 'product__product_price',
      endpoint: '',
      schema: {
        product: DRFObject.$({resource: 'products'}),
        total: DRFDecimal_Money,
        discount_reason: DRFString.$({blank: true}),
        quantity: DRFDecimal.$({decimal_places: 2}),
        unitary_price: DRFDecimal_Money,
      },
    },


    /************ PRODUCT DETAILS ************/
    {
      tag: 'product_details',
      endpoint: 'app/count2/productdetails/',
      schema: {
        id: DRFObject.$({resource: 'product_details'}),
           
        name: DRFString.$({blank: true}),
        product: DRFObject.$({resource: 'products'}),
        type: DRFEnum.$({source: 'product_details.type'}),
        group: DRFObject.$({resource: 'product_detail_groups', null: true}),

        student_number: DRFEnum.$({source: 'products.student_number'}),
        min_quantity: DRFDecimal.$({decimal_places: 2, null: true}),
        max_quantity: DRFDecimal.$({decimal_places: 2, null: true}),
        is_coupon: DRFBoolean.$({null: true}),
        student_enrolled: DRFBoolean.$({null: true}),
        group_is_adquired: DRFBoolean.$({null: true}),
        
        amount: DRFDecimal_Money,
        discount: DRFInteger_Percent.$({display_prefix: '-'}),
        discount_fixed: DRFDecimal_Money.$({display_prefix: '-'}),
        discount_reason: DRFString.$({blank: true}),

        first_month_gen_strategy: DRFEnum.$({source: 'product_details.month_gen_strategy'}),
        first_month_amount: DRFDecimal_Money,
        last_month_gen_strategy: DRFEnum.$({source: 'product_details.month_gen_strategy'}),
        last_month_amount: DRFDecimal_Money,

        conditions: [DRFObject.$({resource: 'product_option_values'})],
        condition_group: DRFObject.$({resource: 'product_detail_groups', null: true}),

        stationery_credit: DRFDecimal_Money.$({null: true, display_prefix: '+'}),
      },
      enums: [
        {
          tag: 'type',
          values: [
            { value: 1, tag: 'single',       title: 'Pago único' },
            { value: 2, tag: 'monthly',      title: 'Mensual' },
            { value: 3, tag: 'on_demand',    title: 'Clase Particular' },
          ]
        },
        {
          tag: 'month_gen_strategy',
          values: [
            { value: 1, tag: 'proportional',    title: 'Parte proporcional' },
            { value: 2, tag: 'whole',           title: 'Mes entero' },
            { value: 3, tag: 'customized',      title: 'Valor personalizado' },
          ]
        },
      ],
      extra: { 
        name: 'cargo de producto', name_plural: 'cargos de productos', name_gender: 'm',
        title: 'name',
        display_columns: [
          { id:  1, order:  1, title: 'Descripción', value: 'name' },
          { id:  2, order:  2, title: 'Producto', value: 'product', optional: true },
          { id:  3, order:  3, title: 'Tipo', value: 'type' },
          { id:  4, order:  4, title: 'Cargo', value: 'amount' },
          { id:  5, order:  5, title: 'Descuento', value: x => 
            (x.discount.valueOf() ? x.discount.toString() : '') + (x.discount.valueOf() && x.discount_fixed.valueOf() ? ', ' : '') + 
            (x.discount_fixed.valueOf() ? x.discount_fixed.toString() : '') + (x.discount_reason.valueOf() ? ' (' + x.discount_reason + ')' : '')  },
          { id: 17, order:  6, title: "Requisitos", value: x => {
            let req = x.conditions.map(t => t.fetch() && (t.option ?? '···').toString() + ': ' + t.name)
            if (x.student_number.valueOf() != 0) req.push(`Núm. Alumnos: ${x.student_number}`)
            if (!x.min_quantity.is_null() && x.max_quantity.is_null()) req.push(`Núm. horas: >= ${x.min_quantity}`)
            else if (x.min_quantity.is_null() && !x.max_quantity.is_null()) req.push(`Núm. horas: <= ${x.max_quantity}`)
            if (!x.min_quantity.is_null() && !x.max_quantity.is_null()) req.push(`Núm. horas: ${x.min_quantity} - ${x.max_quantity}`)
            if (!x.is_coupon.is_null()) req.push(`Es bono: ${x.is_coupon}`)
            if (!x.condition_group.is_null() && !x.group_is_adquired.is_null()) {
              req.push(x.condition_group.toString() + ': ' + (x.group_is_adquired.valueOf() ? 'Adquirido' : "No adquirido"))
            }
            return req
          }},
          { id:  6, order:  6, title: 'Requisitos (opciones)', value: 
            x => x.conditions.map(t => t.fetch() && (t.option ?? '···').toString() + ': ' + t.name), optional: true },
          { id:  7, order:  7, title: 'Req: num. alumnos', value: 'student_number', optional: true },
          { id:  8, order:  8, title: 'Req: min. horas', value: 'min_quantity', optional: true },
          { id:  9, order:  9, title: 'Req: max. horas', value: 'max_quantity', optional: true },
          { id: 10, order: 10, title: 'Req: es bonos', value: 'is_coupon', optional: true },
          { id: 11, order: 11, title: 'Req: está en otro curso', value: 'student_enrolled', optional: true },
          { id: 12, order: 12, title: 'Req: grupo adquirido', value: x => {
            if (x.condition_group.is_null() || x.group_is_adquired.is_null()) return ''
              return x.condition_group.toString() + ': ' + (x.group_is_adquired.valueOf() ? 'Adquirido' : "No adquirido")
            }, optional: true },
          { id: 13, order: 13, title: 'Primer mes', value: x => {
              if (x.type.valueOf() == 2) {
                if (x.first_month_gen_strategy.valueOf() == 3) {
                  return x.first_month_gen_strategy.toString() + ' (' + x.first_month_amount.toString() + ')'
                }
                return x.first_month_gen_strategy.toString()
              }
              return ''
            } },
          { id: 14, order: 14, title: 'Último mes', value: x => {
              if (x.type.valueOf() == 2) {
                if (x.last_month_gen_strategy.valueOf() == 3) {
                  return x.last_month_gen_strategy.toString() + ' (' + x.last_month_amount.toString() + ')'
                }
                return x.last_month_gen_strategy.toString()
              }
              return ''
            } },
          { id: 15, order: 15, title: 'Grupo a adquirir', value: 'group' },
          { id: 16, order: 16, title: 'Crédito de papelería', value: 'stationery_credit' },
        ]
      },
    },


    /************ PRODUCT DETAIL GROUPS ************/
    {
      tag: 'product_detail_groups',
      endpoint: 'app/count2/productdetailgroups/',
      schema: {
        id: DRFObject.$({resource: 'product_detail_groups'}),

        name: DRFString,
        workcenters: [DRFObject.$({resource: 'workcenters'})],

        product_details: [DRFObject.$({resource: 'product_details'})],
        product_details_condition: [DRFObject.$({resource: 'product_details'})],
      },
      enums: [

      ],
      extra: { 
        title: 'name',
        name: 'grupo de cargos de producto', name_plural: 'grupos de cargos de productos', name_gender: 'm',
        display_columns: [
          { id:  1, order:  1, title: 'Nombre', value: 'name' },
          { id:  2, order:  2, title: 'Centros', value: 'workcenters' },
          { id:  3, order:  3, title: 'Usada en (grupo):', value: 
            x => x.product_details.map(t => t.fetch() && (t.product ?? '···') + ': ' + (t.name ?? '···')) },
          { id:  4, order:  4, title: 'Usada en (requisito):', value: 
            x => x.product_details_condition.map(t => t.fetch() && (t.product ?? '···') + ': ' + (t.name ?? '···')) },
        ]
      },
    },


    /************ PRODUCT OPTIONS ************/
    {
      tag: 'product_options',
      endpoint: 'app/count2/productoptions/',
      schema: {
        id: DRFObject.$({resource: 'product_options'}),

        product: DRFObject.$({resource: 'products'}),
        name: DRFString,
        default_value: DRFObject.$({resource: 'product_option_values', null: true}),
        option_type: DRFEnum.$({source: 'product_options.option_type'}),
        group_type: DRFEnum.$({source: 'product_options.group_type'}),

        ui_help: DRFString.$({blank: true}),
      },
      enums: [
        {
          tag: 'option_type',
          values: [
            { value: 1, tag: 'manual',       title: 'Manual' },
          ]
        },
        {
          tag: 'group_type',
          values: [
            { value: 1, tag: 'no_grouping',               title: 'No es grupo' },
            { value: 2, tag: 'group_by_value',            title: 'Grupo' },
            { value: 3, tag: 'group_by_value_and_center', title: 'Grupo por centro' },
          ]
        },
      ],
      extra: { 
        title: 'name',
        name: 'opción de producto', name_plural: 'opciones de productos', name_gender: 'f',
        display_columns: [
          { id:  1, order:  1, title: 'Opción', value: 'name' },
          { id:  2, order:  2, title: 'Producto', value: 'product', optional: true },
          { id:  3, order:  3, title: 'Valor por defecto', value: 'default_value' },
          { id:  4, order:  4, title: 'Agrupamiento', value: 'group_type' },
          { id:  5, order:  5, title: 'Tipo de opción', value: 'option_type', optional: true },
          { id:  6, order:  6, title: 'Texto de ayuda', value: 'ui_help', class: [ 'font-whitespace-pre' ], text_wrap: true },
        ]
      },
    },


    /************ PRODUCT OPTION VALUES ************/
    {
      tag: 'product_option_values',
      endpoint: 'app/count2/productoptionvalues/',
      schema: {
        id: DRFObject.$({resource: 'product_option_values'}),

        option: DRFObject.$({resource: 'product_options'}),
        name: DRFString,
        internal_value: DRFInteger,

        group_size_limit: DRFInteger,
        allow_overbooking: DRFBoolean,
        can_be_reused: DRFBoolean,

        people_in_group: DRFInteger,

        ui_help: DRFString.$({blank: true}),
      },
      extra: { 
        title: 'name',
        name: 'valor de opción de producto', name_plural: 'valores de opciones de productos', name_gender: 'm',
        display_columns: [
          { id:  1, order:  1, title: 'Respuesta', value: 'name' },
          { id:  2, order:  2, title: 'Opción', value: 'option', optional: true },
          { id:  3, order:  3, title: 'Valor interno', value: 'internal_value', optional: true },
          { id:  4, order:  4, title: 'Matrículas asignadas', value: 'people_in_group' },
          { id:  5, order:  5, title: 'Máx. matrículas', value: x => +x.group_size_limit == 0 ? '(Sin máximo)' : x.group_size_limit },
          { id:  6, order:  6, title: 'Overbooking permitido', value: 'allow_overbooking' },
          { id:  7, order:  7, title: 'Se libera tras cancelar', value: 'can_be_reused' },
          { id:  8, order:  8, title: 'Texto de ayuda', value: 'ui_help', class: [ 'font-whitespace-pre' ], text_wrap: true }
        ]
      },
    },


    /************ PRODUCT ORDERS ************/
    {
      tag: 'product_orders',
      endpoint: 'app/count2/productorders/',
      schema: {
        id: DRFObject.$({resource: 'product_orders'}),

        member: DRFObject.$({resource: 'members'}),
        product: DRFObject.$({resource: 'products', null: true}),
        workcenter: DRFObject.$({resource: 'workcenters'}),
        status: DRFEnum.$({source: 'product_orders.status'}),

        after_draft_behavior: DRFEnum.$({source: 'product_orders.after_draft_behavior'}),

        creation_timestamp: DRFDateTime,
        start_date: DRFDate.$({null: true}),
        end_date: DRFDate.$({null: true}),
        quantity: DRFDecimal.$({decimal_places: 2}),
        student_number: DRFEnum.$({source: 'products.student_number'}),
        is_coupon: DRFBoolean,

        options: [DRFObject.$({resource: 'product_option_values'})],

        reserved: DRFBoolean,
        enrolled: DRFBoolean,
        moodle: DRFBoolean,

        comment: DRFString.$({blank: true}),

        on_demand_classes: [DRFObject.$({resource: 'on_demand_classes'})],
      },
      enums: [
        {
          tag: 'status',
          values: [
            { value: 1,   tag: 'draft',             title: 'Borrador' },
            { value: 6,   tag: 'processing',        title: 'Procesando' },
            { value: 3,   tag: 'pending_payment',   title: 'Pendiente de pago' },
            { value: 2,   tag: 'active',            title: 'Activa' },
            { value: 4,   tag: 'cancelled',         title: 'Cancelada' },
            { value: 5,   tag: 'finished',          title: 'Finalizada' },
            { value: 100, tag: 'error_no_stock',  title: 'Error: Sin stock' },
          ]
        },
        {
          tag: 'after_draft_behavior',
          values: [
            { value: 1, tag: 'go_to_pending_payment',             title: 'Ir a pago pendiente' },
            { value: 2, tag: 'reserve_and_go_to_pending_payment', title: 'Reservar e ir a pago pendiente' },
            { value: 3, tag: 'go_to_active',                      title: 'Reservar y matricular ya' },
            { value: 4, tag: 'manual',                            title: 'Matriculación manual' },
          ]
        }
      ],
      actions: [
        {
          tag: 'check_availability',
          method: 'POST',
          detail: false,
        },
        {
          tag: 'generate_cancellation_draft',
          method: 'POST',
          detail: true,
          returns: { schema: {
            current: [DRFObject.$({resource: 'order_invoice_lines'})],
            proposed: [DRFObject.$({resource: 'order_invoice_lines'})],
            op_id: DRFInteger,
          }}
        },
        {
          tag: 'finish_operation',
          method: 'POST',
          detail: true,
          returns: {resource: 'product_orders'},
        },
        {
          tag: 'report',
          method: 'POST',
          detail: false,
          returns: { schema: DRFString },
        }
      ],
      extra: { 
        title: x => 'Alumno id=' + x.member.valueOf() + ' producto id=' + x.product.valueOf(),
        name: 'pedido de producto', name_plural: 'pedidos de productos', name_gender: 'm',
        search: false, 
        default_page_size: 30,
        order_by: [
          { id:  1, title: 'Por defecto: Recientes', value: '' },
          { id:  2, title: 'Inicio matrícula (recientes)', value: '-start_date' },
          { id:  3, title: 'Inicio matrícula (antiguos)', value: 'start_date' },
          { id:  4, title: 'Final matrícula (recientes)', value: '-end_date' },
          { id:  5, title: 'Final matrícula (antiguos)', value: 'end_date' },
          { id:  6, title: 'Fecha creación (recientes)', value: 'id' },
          { id:  7, title: 'Fecha creación (antiguos)', value: '-id' },
        ], 
        filter_by: [
          { id:  1, title: 'Alumno', query_param: 'member' },
          { id:  2, title: 'Producto', query_param: 'product' },
          { id:  3, title: 'Centro', query_param: 'workcenter' },
          { id:  4, title: 'Estado', query_param: 'status' },
          { id:  5, title: 'Fecha creación', query_param: 'creation_timestamp' },
          { id:  6, title: 'Fecha inicio matrícula', query_param: 'start_date' },
          { id:  7, title: 'Fecha final matrícula', query_param: 'end_date' },
          { id:  8, title: 'Cantidad', query_param: 'quantity' },
          { id:  9, title: 'Número estudiantes (particular)', query_param: 'student_number' },
          { id: 13, title: 'Es bono (particular)', query_param: 'is_coupon' },
          { id: 10, title: 'Reserva', query_param: 'reserved' },
          { id: 11, title: 'Matrícula', query_param: 'enrolled' },
          { id: 12, title: 'Moodle', query_param: 'moodle' },
        ],
        display_columns: [
          { id:  1, order:   1, title: 'Producto', value: 'product' },
          { id:  2, order:   2, title: 'Centro', value: 'workcenter' },
          { id:  3, order:   3, title: 'Inicio matrícula', value: 'start_date' },
          { id:  4, order:   4, title: 'Final matrícula', value: 'end_date' },
          { id:  5, order:12.3, title: 'Inicio curso', value: 'product', value_attr: 'start_date' },
          { id:  6, order:12.4, title: 'Final curso', value: 'product', value_attr: 'end_date' },
          { id:  7, order:   7, title: 'Alumno', value: 'member', optional: true },
          { id:  8, order: 2.5, title: 'Estado', value: 'status' },
          { id:  9, order:   9, title: 'Fecha creación', value: 'creation_timestamp', optional: true },
          { id: 10, order:  10, title: 'Cantidad', value: 'quantity', optional: true },
          { id: 11, order:  11, title: 'Número estudiantes', value: 'student_number', optional: true },
          { id: 12, order:  12, title: 'Opciones', value: x => x.options.map(t => t.fetch() && (t.option ?? '···').toString() + ': ' + t.name) },
          { id: 13, order:  13, title: 'Reserva', value: 'reserved', optional: true },
          { id: 14, order:  14, title: 'Matrícula', value: 'enrolled', optional: true },
          { id: 15, order:  15, title: 'Moodle', value: 'moodle', optional: true },
          { id: 16, order:12.1, title: 'Notas', value: 'comment' },
          { id: 17, order: 2.9, title: 'Duración clase', value: x => {
            if (x.on_demand_classes.length == 0) return ""
            let cls = x.on_demand_classes[0]
            cls.fetch()
            if (cls.duration === undefined) return "···"
            return cls.duration
          }, optional: true },
          { id: 18, order:11.1, title: 'Es bono', value: 'is_coupon', optional: true },
          { id: 19, order:2.92, title: 'Profesor', value: x => {
            if (x.on_demand_classes.length == 0) return ""
            let cls = x.on_demand_classes[0]
            cls.fetch()
            if (cls.teacher === undefined) return "···"
            return cls.teacher
          }, optional: true },
        ]
      },
    },

    /************ ORDER INVOICE LINE ************/
    {
      tag: 'order_invoice_lines',
      endpoint: 'app/count2/orderinvoicelines/',
      schema: {
        id: DRFObject.$({resource: 'order_invoice_lines'}),

        order: DRFObject.$({resource: 'product_orders', null: true}),
        product_detail: DRFObject.$({resource: 'product_details', null: true}),
        member: DRFObject.$({resource: 'members'}),
        workcenter: DRFObject.$({resource: 'workcenters'}),
        
        payment_date: DRFDate,
        creation_timestamp: DRFDateTime,

        concept: DRFString,

        quantity: DRFDecimal.$({decimal_places: 2}),
        base_amount: DRFDecimal_Money,
        discount: DRFInteger_Percent.$({display_prefix: '-'}),
        fixed_discount: DRFDecimal_Money.$({display_prefix: '-'}),
        discount_reason: DRFString.$({blank: true}),
        amount: DRFDecimal_Money,
        status: DRFEnum.$({source: 'order_invoice_lines.status'}),

        billed_period_start: DRFDate.$({null: true}),
        billed_period_end: DRFDate.$({null: true}),

        cancelled: DRFDecimal_Money,

        receipt: DRFObject.$({resource: 'payment_receipts', null: true}),

        draft_status: DRFEnum.$({source: 'order_invoice_lines.status'}),
        draft_op_id: DRFInteger,
        draft_of: DRFObject.$({resource: 'order_invoice_lines', null: true})
      },
      enums: [
        {
          tag: 'status',
          values: [
            { value: 1, tag: 'draft',         title: 'Borrador' },
            { value: 2, tag: 'pending',       title: 'Pendiente' },
            { value: 3, tag: 'paid',          title: 'Pagado' },
            { value: 4, tag: 'voided',        title: 'Anulado' },
            { value: 5, tag: 'cancelled',     title: 'Devuelto' },
          ]
        },
      ],
      actions: [
        {
          tag: 'perform_payments',
          method: 'POST',
          detail: false,
          returns: {resource: 'payment_receipts'},
        },
      ],
      extra: { 
        title: x => x.concept,
        name: 'pago de producto', name_plural: 'pagos de productos', name_gender: 'm',
        search: true, 
        default_page_size: 30,
        order_by: [
          { id:  1, title: 'Por defecto: Antiguos', value: '' },
          { id:  2, title: 'Fecha vencimiento (recientes)', value: '-payment_date,-id', add_columns: [1] },
          { id:  3, title: 'Fecha vencimiento (antiguos)', value: 'payment_date' },
          { id:  4, title: 'Fecha creación (recientes)', value: 'id' },
          { id:  5, title: 'Fecha creación (antiguos)', value: '-id' },
        ], 
        filter_by: [
          { id:  1, title: 'Alumno', query_param: 'member' },
          { id:  2, title: 'Centro', query_param: 'workcenter'},
          { id:  3, title: 'Vencimiento', query_param: 'payment_date' },
          { id:  3, title: 'Fecha creación', query_param: 'creation_timestamp' },
          { id:  3, title: 'Cantidad', query_param: 'quantity' },
          { id:  4, title: 'Precio unit.', query_param: 'base_amount' },
          { id:  5, title: 'Estado', query_param: 'status' },
          { id:  6, title: 'Modo de pago', query_param: 'member__payment_method', type: DRFEnum.$({source: 'members.payment_method'}) },
          { id:  7, title: 'Última visita al centro', query_param: 'member__last_in_out_date', type: DRFDateTime.$({null: true}) },
        ],
        display_columns: [
          { id:  1, order:  1, title: 'Vencimiento', value: 'payment_date' },
          { id: 10, order:1.5, title: 'Fecha creación', value: 'creation_timestamp', optional: true },
          { id:  2, order:  2, title: 'Concepto', value: 'concept' },
          { id:  3, order:  3, title: 'Descuento', value: x => 
            (x.discount.valueOf() ? x.discount.toString() : '') + (x.discount.valueOf() && x.fixed_discount.valueOf() ? ', ' : '') + (x.fixed_discount.valueOf() ? x.fixed_discount.toString() : '') + (x.discount_reason.valueOf() ? ' (' + x.discount_reason + ')' : '')  },
          { id:  4, order:  5, title: 'Precio unit.', value: 'base_amount' },
          { id:  5, order:  6, title: 'Cantidad', value: 'quantity' },
          { id:  6, order:  7, title: 'Importe', value: 'amount', class: ['font-weight-medium'] },
          { id: 14, order:7.5, title: 'Devuelto', value: 'cancelled', optional: true },
          { id:  7, order:  8, title: 'Centro', value: 'workcenter' },
          { id:  8, order:  9, title: 'Alumno', value: 'member', optional: true },
          { id:  9, order:1.6, title: 'Estado', value: 'status', optional: true },
          { id: 14, order:1.7, title: 'Estado (borrador)', value: 'draft_status', optional: true },
          { id: 11, order: 11, title: 'Inicio periodo facturado', value: 'billed_period_start', optional: true },
          { id: 12, order: 12, title: 'Fin periodo facturado', value: 'billed_period_end', optional: true },
          { id: 13, order: 13, title: 'Periodo facturado', value: x => 
            {
              let r = ""
              if (!x.billed_period_start.is_null()) r += x.billed_period_start.toString()
              if (!x.billed_period_start.is_null() && !x.billed_period_end.is_null()) r += ' - '
              if (!x.billed_period_end.is_null()) r += x.billed_period_end.toString()
              return r
            } 
          },
        ]
      },
    },

    /************ ON DEMAND CLASSES ************/
    {
      tag: 'on_demand_classes',
      endpoint: 'app/count2/ondemandclasses/',
      schema: {
        id: DRFObject.$({resource: 'on_demand_classes'}),

        student: DRFObject.$({resource: 'members'}),
        teacher: DRFObject.$({resource: 'members', null: true}),
        product: DRFObject.$({resource: 'products', null: true}),
        order: DRFObject.$({resource: 'product_orders', null: true}),
        workcenter: DRFObject.$({resource: 'workcenters'}),

        date: DRFDate,
        duration: DRFDecimal.$({decimal_places: 2, display_suffix: ' horas'}),
        student_number: DRFEnum.$({source: 'products.student_number'}),
        creation_timestamp: DRFDateTime,
        start_time: DRFTime.$({null: true}),

        is_coupon: DRFBoolean,
        coupon_credit: DRFDecimal_Money,

        comment: DRFString.$({blank: true})
      },
      extra: { 
        title: x => x.member + ' ' + x.duration,
        name: 'clase particular', name_plural: 'clases particulares', name_gender: 'f',
        search: false, 
        default_page_size: 30,
        order_by: [
          { id:  1, title: 'Por defecto: Nuevos', value: '' },
          { id:  2, title: 'Fecha creación (recientes)', value: 'id' },
          { id:  3, title: 'Fecha creación (antiguos)', value: '-id' },
          { id:  4, title: 'Fecha clase (recientes)', value: '-date' },
          { id:  5, title: 'Fecha clase (antiguos)', value: 'date' },
        ], 
        filter_by: [
          { id:  1, title: 'Alumno', query_param: 'student' },
          { id:  2, title: 'Profesor', query_param: 'teacher' },
          { id:  3, title: 'Tipo', query_param: 'product' },
          { id:  4, title: 'Centro', query_param: 'workcenter' },
          { id:  5, title: 'Fecha clase', query_param: 'date' },
          { id:  6, title: 'Fecha creación', query_param: 'creation_timestamp' },
          { id:  7, title: 'Duración', query_param: 'duration' },
          { id:  8, title: 'Número de estudiantes', query_param: 'student_number' },
          { id:  9, title: 'Hora inicio', query_param: 'start_time' },
        ],
        display_columns: [
          { id:  1, order:   1, title: 'Fecha', value: 'date' },
          { id: 13, order: 1.5, title: 'Hora inicio', value: 'start_time' },
          { id:  2, order:   2, title: 'Alumno', value: 'student' },
          { id:  3, order:   3, title: 'Profesor', value: 'teacher' },
          { id:  4, order:   4, title: 'Tipo', value: 'product' },
          { id:  5, order:   5, title: 'Duración', value: 'duration' },
          { id:  6, order:   7, title: 'Centro', value: 'workcenter' },
          { id:  7, order:   8, title: 'Comentarios', value: 'comment' },
          { id:  8, order:   9, title: 'Fecha creación', value: 'creation_timestamp', optional: true },
          { id: 10, order:  10, title: 'Es bono', value: 'is_coupon', optional: true },
          { id: 13, order: 7.5, title: 'Crédito', value: 'coupon_credit', optional: true },
          { id: 11, order:  11, title: '¿Pagado?', value: x => x.order.is_null() ? '' : x.order.fetch() && x.order.status ? +x.order.status == 5 ? 'Pagado' : 'Pendiente' : '···' },
          { id: 14, order:  12, title: 'Número de estudiantes', value: 'student_number', optional: true },
        ]

      },
    },    
  
  ],
}