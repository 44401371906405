import { 
  DRFDatatype, 
  DRFString, 
  DRFBoolean, 
  DRFInteger, 
  DRFFloat,
  DRFDecimal,
  DRFDateTime,
  DRFDate,
  DRFTime,
  DRFDuration,
  DRFEnum,
  DRFObject
 } from './datatypes.js'


export class DRFString_Phone extends DRFString {
  to_user() {
    const stripped = this._value.replace(/\s/g, '')
    if (stripped.length == 9) {
      return stripped[0] + stripped[1] + stripped[2] + ' ' + stripped[3] + stripped[4] + ' ' + stripped[5] + stripped[6] + ' ' + stripped[7] + stripped[8]
    }
    else if (stripped.length == 12 && stripped[0] == '+') {
      return stripped[0] + stripped[1] + stripped[2] + '  ' + stripped[3] + stripped[4] + stripped[5] + ' ' + stripped[6] + stripped[7] + ' ' + stripped[8] + stripped[9] + ' ' + stripped[10] + stripped[11]
    }
  
    return this._value
  }

  to_server(query = false) {
    const r = super.to_server(query)
    if (typeof r == 'string') return r.replace(/\s/g, '')
    else return r
  }
}


export class DRFDate_Birthday extends DRFDate {
  display_age() {
    if (!this._value) return ""

    // Note it is done this way to take into account leap years
    let today = new Date()
    let age = today.getFullYear() - this._value.getFullYear()
    let m = today.getMonth() - this._value.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < this._value.getDate())) {
      age--
    }

    let display_age_suffix = this.get_annotations().display_age_suffix || ''
    if (display_age_suffix) display_age_suffix = ' ' + display_age_suffix

    return age.toString() + display_age_suffix
  }
}

export const DRFDecimal_Money = DRFDecimal.$({display_suffix: '€', decimal_places: 2})
export const DRFInteger_Percent = DRFInteger.$({display_suffix: '%', display_suffix_space: ' '})