<template>
  <div class="page">
    <h1 class="text-h4 text-page_title">Inicio</h1>

    <v-container>
      <outter-card>
        <v-card-text>
          <span>{{ config.welcome_msg }}</span>
        </v-card-text>
      </outter-card>
      <div class="d-flex">
        <v-spacer></v-spacer><h6>Versión {{ config.appVersion }}</h6>
      </div>
    </v-container>
  </div>
</template>

<script></script>