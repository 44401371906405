<template>
  <v-dialog :width="width" class="dialog-size-hack"
    :modelValue="show"
    @update:modelValue="updt_val"
    :persistent="persistent"
    ref="dialog"
    scrollable
  >
    <v-card>
      <v-form ref="form" @submit.prevent="validate">

        <v-toolbar color="app_bar" class="mb-5 py-3" height="compact">
          <v-toolbar-title bg-color="app_bar" >
            <div>
              <span>{{ title }}</span>
              <span class="mx-2" v-if="titledetail">-</span>
              <span class="font-weight-light" v-if="titledetail">{{ titledetail }}</span>
            </div>
          </v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <slot></slot>
          <small v-if="footnote">{{ footnote }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey-darken-2"
            variant="text"
            @click="cancel"
            v-if="cancelbutton"
          >
            {{ canceltext }}
          </v-btn>
          <v-btn
            color="primary"
            variant="text"
            type="submit"
            ref="okbutton"
          >
            {{ oktext }}
          </v-btn>
        </v-card-actions>

      </v-form>
    </v-card>
  </v-dialog>
</template>


<style scoped>
.dialog-size-hack :deep(.v-overlay__content) {
  width: 100%;
}
</style>


<script>
import { nextTick } from 'vue'

export default {

  props: {
    modelValue: {}, 
    title: { default: "Diálogo" },
    titledetail: {},
    canceltext: { default: "Cancelar" },
    cancelbutton: { default: true },
    footnote: { default: "* indica campo requerido" },
    oktext: { default: "Aceptar" },
    width: { default: "70%" },
    persistent: { default: false },
  },
  emits: [ 'update:modelValue', 'open', 'close', 'reset' ],

  data() {
    return {
      // Manually debounce the button clicks, otherwise we cannot ask the user
      // to click again in case of a form validation error
      debounce: false
    }
  },

  mounted() { 

  },

  computed: {
    show: {
      get() {
        return this.modelValue
      },
      set(v) {
        this.$emit('update:modelValue', v)
      }
    }
  },

  watch: {
    show(v) {
      if (v) {
        this.debounce = false
        nextTick(() => { this.$refs.okbutton.$el.focus() })
        this.$emit('open')
      }
    }
  },

  methods: {
    cancel() {
      if ( this.debounce ) return
      this.debounce = true
      this.$emit('close', false)
    },
    validate() {
      if ( this.debounce || !this.$refs.form ) return
      this.debounce = true
      this.$refs.form.validate()
        .then((d) => { 
          if (!d.valid) {
            this.vibrate()
            this.cancelclose()
            return
          }
          
          this.$emit('close', true)
        })
    },
    vibrate() {
      this.$refs.dialog.animateClick()
    },
    cancelclose(vibrate=false) {
      if (vibrate) this.vibrate()
      this.debounce = false
    },
    updt_val(v) {
      // Autoclose (by clicking outside the dialog, for example)
      if (!v && !this.persistent) this.cancel()
    }

  }

}
</script>