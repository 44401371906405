import { store } from "./store"
import { nextTick } from 'vue'

import { API } from "@/API/api"
import api_endpoints from "@/API/api_endpoints"
import { null_if_empty, APIDecimal, APIMoney, APIDate, APIElapsed, prettify_phone, canonify_phone } from "@/API/api_util"

import { 
  DRFDatatype, 
  DRFString, 
  DRFBoolean, 
  DRFInteger, 
  DRFFloat,
  DRFDecimal,
  DRFDateTime,
  DRFDate,
  DRFTime,
  DRFDuration,
  DRFEnum,
  DRFObject
 } from './API/datatypes.js'

 import {
  DRFDecimal_Money,
  DRFInteger_Percent
 } from './API/ext_datatypes.js'

export const template_helpers = {

  // LOADED FROM API
  api: new API(api_endpoints),
  null_if_empty: null_if_empty,
  d: {
    decimal: APIDecimal,
    money: APIMoney,
    date: APIDate,
    elapsed: APIElapsed,
  },
  prettify_phone: prettify_phone,
  canonify_phone: canonify_phone,
  DRFDatatype: DRFDatatype,
  DRFString: DRFString, 
  DRFBoolean: DRFBoolean, 
  DRFInteger: DRFInteger, 
  DRFFloat: DRFFloat,
  DRFDecimal: DRFDecimal,
  DRFDateTime: DRFDateTime,
  DRFDate: DRFDate,
  DRFTime: DRFTime,
  DRFDuration: DRFDuration,
  DRFEnum: DRFEnum,
  DRFObject: DRFObject,
  DRFDecimal_Money: DRFDecimal_Money,
  DRFInteger_Percent: DRFInteger_Percent,

  store: store,

  user_associated_member() {
    const x = this.store.user?.userprofile?.associated_member
    if (!x || x.is_null()) return null
    return x
  },

  get_user() {
    return {
      can_see_payment_details: store.user.userprofile.can_see_payment_details.valueOf() || store.user.is_superuser.valueOf(),
      is_superuser: store.user.is_superuser.valueOf(),
    }
  },

  async load_app_global_data() {
    try {
      this.store.workcenters = this.api.workcenters.list_all()
    }
    catch (e) {
      this.error_msg(this.api.err_to_msg(e), "No se han podido cargar todos los datos.")
    }
  },

  load_status_of(obj, expected_length) {
    if (typeof obj === 'string' || obj instanceof String) return obj

    if (obj === 'undefined') return 'loaded'
    return obj.load_status
  },

  load_status_list(obj, expected_length) {
    if (expected_length === undefined || expected_length === 0) {
      if (!obj?.length) return []
      else return ['loading']
    }

    if (obj?.length != expected_length) return ['loading']

    return obj.map(x => (typeof x === 'string' || x instanceof String) ? x : x.load_status)
  },

  emit_load_event(list, err_msg) {
    let args = []
    if (list.every(x => x == 'loaded')) args = ['loaded', ""]
    else if (list.some(x => x == 'error')) args = ['error', this.load_error_msg || ""]
    else args = ['loading', ""]

    if (args[0] != this.load_status) {
      this.load_status = args[0]
      this.$emit('load-event', args[0], args[1])
    }
  },

  success_msg(msg) {
    store.success_msg = msg
  },
  
  info_msg(msg) {
    store.info_msg = msg
  },

  warning_msg(msg) {
    store.warning_msg = msg
  },

  error_msg(msg) {
    store.error_msg = msg
  },

  msg_box: (text, title = "Aviso", icon = "mdi-alert-circle-outline") => {
    store.msg_box_queue.push({
      text: text,
      title: title,
      icon: icon,
      cancelbutton: false,
      oktext: "Ok",
      canceltext: null,
    })
  },

  msg_box_yes_no: (text, title = "Aviso", icon = "mdi-alert-circle-outline", danger_word = null) => {
    return new Promise((resolve) => {
      store.msg_box_queue.push({
        text: text,
        title: title,
        icon: icon,
        cancelbutton: true,
        oktext: "Sí",
        canceltext: "No",
        danger_word: danger_word,
        result: (v) => {
          if (v == 0) resolve(true)
          else if (v == 1) resolve(false)
        }
      })
    })
  },

  // Update engine is hierarchical: if one component pushes an update to
  // 'app.object.data' then update_check will return true for either
  // 'app', 'app.object' or 'app.object.data'
  update: (reason, timeout = null) => {
    if (!timeout) {
      nextTick(() => { template_helpers._update_inner(reason) })
    }
    else {
      setTimeout(() => { nextTick(() => { template_helpers._update_inner(reason) }) }, timeout)
    }
  },

  _update_inner: (reason) => {
    store.update_reason[store.update + 1] = reason
    const updt = store.update
    nextTick(() => { nextTick(() => { template_helpers._update_finished(updt + 1) })})
    store.update++
  },

  _update_finished: (id) => {
    delete store.update_reason[id]
  },

  update_check: (reason, last_update) => {
    const current = store.update
    for (let i = Math.max(last_update + 1, Math.min(Object.keys(store.update_reason))); i <= current; i++) {
      if (template_helpers._update_check_inner(reason, store.update_reason[i])) return true
    }

    return false
  },

  _update_check_inner: (r1, r2) => {
    return r1.startsWith(r2)
  },

  shallow_compare(obj1, obj2) {
    // Null
    if (obj1 === null && obj2 === null) return true
    if (obj1 === null || obj2 === null) return false

    if (typeof obj1 != typeof obj2) return false

    // Values
    if (typeof obj1 != 'object') {
      return obj1 === obj2
    }
    
    // Objects
    return Object.keys(obj1).length === Object.keys(obj2).length &&
    Object.keys(obj1).every(key => 
      Object.prototype.hasOwnProperty.call(obj2, key) && obj1[key] === obj2[key]
    )
  },

  deep_compare(obj1, obj2) {
    // Null
    if (obj1 === null && obj2 === null) return true
    if (obj1 === null || obj2 === null) return false

    if (typeof obj1 != typeof obj2) return false

    // Values
    if (typeof obj1 != 'object') {
      return obj1 === obj2
    }

    // // Arrays
    // if (Array.isArray(obj1) != Array.isArray(obj2)) return false
    // if (Array.isArray(obj1)) {
    //   if (obj1.length != obj2.length) return false
    //   for (let i = 0; i < obj1.length; i++) {
    //     if (!template_helpers.deep_compare(obj1[i], obj2[i])) return false
    //   }
    //   return true
    // }

    // Objects & arrays
    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)
    if (keys1.length != keys2.length) return false
    return keys1.every(key => template_helpers.deep_compare(obj1[key], obj2[key]))  
  },

  intersection(arr) {
    return arr.reduce((a, c) => a.filter(i => c.includes(i)))
  },

  items_yes_no: [
    {value: true, title: 'Sí'},
    {value: false, title: 'No'}
  ],

  // The guys at vuetify are still deciding whether null is a valid value for select and combobox
  // until then we need the conversion functions which appear afterward
  items_yes_no_null: [
    {value: 'null', title: 'Sin especificar'},
    {value: true, title: 'Sí'},
    {value: false, title: 'No'}
  ],

  yes_no_null(v) {
    return {
      to_server() {
        if (v === 'null') return null
        return v
      },

      to_user() {
        if (v === null) return 'null'
        return v
      }
    }
  },

  rul: {
    required: value => (value !== null && value !== undefined && value !== "" && (!(Array.isArray(value)) || value.length > 0)) || 'Campo requerido.',
    counter: (m) => (value => value.length <= m || 'Demasiado largo.'),
    currency: value => ( APIMoney.check_money(value) || 'Importe no válido.' ),
    gt: (v, f, g) => (value => (f ? f(value) : value) > (g ? g(v) : v) || 'El valor debe ser mayor que ' + v + '.' ),
    gte: (v, f, g) => (value => (f ? f(value) : value) >= (g ? g(v) : v) || 'Valor mínimo: ' + v + '.' ),
    lt: (v, f, g) => (value => (f ? f(value) : value) < (g ? g(v) : v) || 'El valor debe ser menor que ' + v + '.' ),
    lte: (v, f, g) => (value => (f ? f(value) : value) <= (g ? g(v) : v) || 'Valor máximo: ' + v + '.' ),
    email: value => ( !value || /^[A-Za-z0-9+_.-]+@(.+)\.(.+)$/.test(value) || "Email no válido." ),
    phone: value => ( !value || /^\+?[\d\s]+$/.test(value) || "Teléfono no válido." ),

    gt_attr: (v) => (value => !value || value.gt(v) || 'El valor debe ser mayor que ' + v + '.' ),
    gte_attr: (v) => (value => !value || value.gte(v) || 'Valor mínimo: ' + v + '.' ),
    lt_attr: (v) => (value => !value || value.lt(v) || 'El valor debe ser menor que ' + v + '.' ),
    lte_attr: (v) => (value => !value || value.lte(v) || 'Valor máximo: ' + v + '.' ),



    gt_money: v => template_helpers.rul.gt(v, (p) => APIMoney.from_user(p).to_internal(), (p) => APIMoney.from_user(p).to_internal()),
    gte_money: v => template_helpers.rul.gte(v, (p) => APIMoney.from_user(p).to_internal(), (p) => APIMoney.from_user(p).to_internal()),
    lt_money: v => template_helpers.rul.lt(v, (p) => APIMoney.from_user(p).to_internal(), (p) => APIMoney.from_user(p).to_internal()),
    lte_money: v => template_helpers.rul.lte(v, (p) => APIMoney.from_user(p).to_internal(), (p) => APIMoney.from_user(p).to_internal()),

    gt_decimal: v => template_helpers.rul.gt(v, (p) => APIDecimal.from_user(p).to_internal(), (p) => APIDecimal.from_user(p).to_internal()),
    gte_decimal: v => template_helpers.rul.gte(v, (p) => APIDecimal.from_user(p).to_internal(), (p) => APIDecimal.from_user(p).to_internal()),
    lt_decimal: v => template_helpers.rul.lt(v, (p) => APIDecimal.from_user(p).to_internal(), (p) => APIDecimal.from_user(p).to_internal()),
    lte_decimal: v => template_helpers.rul.lte(v, (p) => APIDecimal.from_user(p).to_internal(), (p) => APIDecimal.from_user(p).to_internal()),

  },

  url_rewrite_params(params, query = {}, replace = false) {
    store.no_scroll_to_top = true
    let new_route = {...this.$route}
    new_route.params = {...new_route.params, ...params}
    new_route.query = {...new_route.query, ...query}
    if (!replace) this.$router.push(new_route)
    else this.$router.replace(new_route)
  },

  url_rewrite_query_params(query, replace = false) {
    store.no_scroll_to_top = true
    let new_route = {...this.$route}
    new_route.query = {...new_route.query, ...query}
    if (!replace) this.$router.push(new_route)
    else this.$router.replace(new_route)
  },

  array_as_multiple(array) {
    array.serialize_as = 'multiple'
    return array
  },

  array_as_csv(array) {
    array.serialize_as = 'csv'
    return array
  },

  console_log(x, r = undefined) {
    console.log(x)
    return r === undefined ? x : r
  },

  assert_debug(x) {
    if (!x && this.config.debug) debugger
    return true
  }

}
