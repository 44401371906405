<template>

  <v-app-bar app flat color="app_bar">
    <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    <v-toolbar-title class="">
      <span>{{ config.company }}</span>
      <span class="mx-4"></span>
      <span class="font-weight-light">{{ config.short_title }}</span>
    </v-toolbar-title>
    <v-spacer />
    <v-progress-circular indeterminate v-if="store.loading > 0" class="mr-3" />
    <h6>[{{ store.user.first_name.toString() ? store.user.first_name.toString() + (store.user.last_name.toString() ? ' ' + store.user.last_name.toString() : '') : store.user.username.toString() }}]</h6>
    <v-btn flat color="white" append-icon="mdi-exit-to-app" @click="logout_click()">
      <span>Salir</span>
    </v-btn>
  </v-app-bar>

  <v-navigation-drawer app v-model="drawer" >
    <v-list :lines="false" density="compact" nav>

      <v-list-subheader>Gestiones Básicas</v-list-subheader>
      
      <v-divider></v-divider>
      <v-list-item 
        v-for="(link, i) in link_list" 
        :key="i"
        router 
        :to="link.route" 
        color="primary-lighten-1"
        :prepend-icon="link.icon"
        :disabled="link.disabled">
        <v-list-item-title>{{ link.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>


</template>

<script>
import { store } from '../store.js'
import { logout } from '@/API/api.js'
import { useStorage } from '@vueuse/core'


const debug = false

export default {
  data() {
    return {
      drawer: true,
      basic_links: [
        { icon: 'mdi-star', text: 'Inicio', route: '/', disabled: false, hidden: false },
        { icon: 'mdi-account-multiple', text: 'Alumnos', route: '/alumnos', disabled: false, hidden: false },
        { icon: 'mdi-book-open-page-variant', text: 'Matriculación', route: '/matriculacion' },
        { icon: 'mdi-currency-eur', text: 'Cobros', route: '/cobros' },
        { icon: 'mdi-cash-multiple', text: 'Recibos de pago', route: '/lista-recibos-pago', payment_details: true },
        { icon: 'mdi-cash-off', text: 'Impagos', route: '/impagos', payment_details: true },
        { icon: 'mdi-book-open-page-variant', text: 'Registro clases particulares', route: '/registro-particulares' },
        { icon: 'mdi-notebook-edit', text: 'Editor de cursos', route: '/editor-cursos', payment_details: true },
        { icon: 'mdi-contacts', text: 'Contacto / Incidencias', route: '/contacto' },
        { icon: 'mdi-bookshelf', text: 'Informes', route: '/informes', payment_details: true },
        { icon: 'mdi-dots-horizontal', text: 'Avanzado', route: '/avanzado', disabled: '!debug' , hidden: false },
        { icon: 'mdi-test-tube', text: 'Testing', route: '/testing', disabled: false, hidden: '!debug' },
      ],
      store,
      token: useStorage('login-token', '')
    }
  },

  created() {
    for (const link of this.basic_links) {
      if (link.disabled == 'debug') {
        link.disabled = this.config.debug
      }      
      if (link.disabled == '!debug') {
        link.disabled = !this.config.debug
      }
      if (link.hidden == 'debug') {
        link.hidden = this.config.debug
      }
      if (link.hidden == '!debug') {
        link.hidden = !this.config.debug
      }
    }
  },

  computed: {
    link_list() {
      return this.basic_links.filter(x => {
        if (x.hidden) return false
        if (x.payment_details) return this.get_user().can_see_payment_details
        return true
      })
    }
  },

  methods: {
    logout_click() {
      logout()
      this.token=""
      this.$router.push({ 'name': 'login' })
    }
  }

}
</script>