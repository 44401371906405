
export function null_if_empty(v) {
  if (typeof v === 'string' && v.length == 0) return null
  return v
}


export class APIDecimal {
  static check_decimal(v, digits=10, decimal_digits=2) {
    if (digits == 10 && decimal_digits == 2) {
      return /^\s*-?\d{1,10}([.,]\d{0,2})?\s*$/.test(v)
    }
  
    throw 'Not implemented'
  }

  static from_user(v) {
    return new APIDecimal(v, 'user')
  }
  
  static from_server(v) {
    return new APIDecimal(v, 'server')
  }
  
  static from_internal(v) {
    return new APIDecimal(v, 'internal')
  }
  
  static from_float(v) {
    return new APIDecimal(v, 'float')
  }

  constructor(v, type, digits=10, decimal_digits=2, default_sufix='', default_prefix='') {

    if (v === null) {
      this.value = null
      return
    }

    if (type == 'user' || type == 'server') {
      if (typeof v === 'string' || v instanceof String) {
        let m = null
        if (digits == 10 && decimal_digits == 2) {
          m = v.match(/^\s*(-?)(\d{1,10})([.,](\d{0,2}))?\s*$/)
        }
        else throw 'Not implemented'
  
        if (!m) throw "Invalid value."
  
        if (m[4] == undefined) m[4] = ''
        m[4] = m[4].padEnd(2, '0')
  
        v = (m[1] == '-' ? -1 : 1) * parseInt(m[2] + m[4])
      }
      else {
        v = Math.round(v * 100)
      }
    }
    else if (type == 'float') {
      v = Math.round(v * 100)
    }
    else if (type == 'internal') {
      // No need to parse
    }
    else throw "Invalid type"

    this.value = v
    this.default_prefix = default_prefix
    this.default_sufix = default_sufix
  }

  to_user(sufix=null, prefix=null, num_dec=2) {
    if (!this.value) return ""
    return (prefix || this.default_prefix) + (this.value/100).toFixed(num_dec).replace('.', ',') + (sufix || this.default_sufix)
  }

  to_server() {
    if (!this.value) return null
    return (this.value/100).toFixed(2)
  }

  to_internal() {
    return this.value
  }

  to_float() {
    if (!this.value) throw new Error("APIDecimal is null.")
    return this.value / 100
  }
}


export class APIMoney extends APIDecimal {
  static check_money(v) {
    v = v.trimEnd()
    if (v.slice(-1) == '€') {
      v = v.slice(0, -1)
    }
  
    return APIDecimal.check_decimal(v)
  }

  static from_user(v) {
    return new APIMoney(v, 'user')
  }
  
  static from_server(v) {
    return new APIMoney(v, 'server')
  }
  
  static from_internal(v) {
    return new APIMoney(v, 'internal')
  }
  
  static from_float(v) {
    return new APIMoney(v, 'float')
  }


  constructor(v, type) {
    if (typeof v === 'string' || v instanceof String) {
      v = v.trimEnd()
      if (v.slice(-1) == '€') {
        v = v.slice(0, -1)
      }
    }
  
    super(v, type, 10, 2, '€')
  }
}


export class APIDate  {

  static from_server(v, hasDate = undefined, hasTime = undefined) {
    return new APIDate(v, 'server', hasDate, hasTime)
  }

  static from_now(hasDate = undefined, hasTime = undefined) {
    return new APIDate(null, 'now', hasDate, hasTime)
  }

  static from_textfield(v, hasDate = undefined, hasTime = undefined) {
    return new APIDate(v, 'textfield', hasDate, hasTime)
  }

  constructor(v, type, hasDate = undefined, hasTime = undefined) {
    this.dt = null

    if (type == 'now') {
      this.dt = new Date()
      if (hasDate === undefined) hasDate = true
      if (hasTime === undefined) hasTime = true
    }
    else if (v instanceof Date) {
      this.dt = v
      if (hasDate === undefined) hasDate = true
      if (hasTime === undefined) hasTime = true
    }
    else {
      if (type == 'server' || type == 'textfield') {
        if (typeof v === 'string' || v instanceof String) {
          this.dt = new Date(v)
          if (v.includes('-')) hasDate = true
          if (v.includes(':')) hasTime = true
        }
      }
    }

    this.hasDate = hasDate
    this.hasTime = hasTime
  }
  
  to_user() {
    if (!this.dt) return ""
    let r = ""
    
    if (this.hasDate) {
      r += this.dt.getDate().toString().padStart(2, '0') + '/' 
        + (this.dt.getMonth() + 1).toString().padStart(2, '0') + '/'
        + this.dt.getFullYear() 
    }

    if (this.hasTime) {
      if (r) r += ' '
      r += this.dt.getHours().toString().padStart(2, '0') + ':'
        + this.dt.getMinutes().toString().padStart(2, '0') + ':'
        + this.dt.getSeconds().toString().padStart(2, '0') 
    }

    return r
  }

  to_server() {
    if (!this.dt) return null
    if (this.hasTime && this.hasDate) return this.dt.toISOString().slice(0,19)
    else if (this.hasDate) return this.dt.toISOString().slice(0,10)
    else if (this.hasTime) return this.dt.toISOString().slice(11,19)
  }

  to_internal() {
    return this.dt
  }

  to_date() {
    if (!this.dt) throw new Error("APIDate is null.")
    return this.dt
  }

  add_days(n) {
    if (!this.dt) throw new Error("APIDate is null.")
    this.dt.setDate(this.dt.getDate() + n)
    return this
  }

  elapsed() {
    if (!this.dt) return new APIElapsed(null)
    return this.dt ? new APIElapsed((new Date()).getTime() - this.dt.getTime()) : null
  }

  to_age(suffix = ' años') {
    if (!this.dt) return ""

    // Note it is done this way to take into account leap years
    let today = new Date()
    let age = today.getFullYear() - this.dt.getFullYear()
    let m = today.getMonth() - this.dt.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < this.dt.getDate())) {
      age--
    }

    return age.toString() + suffix
  }
}

export class APIElapsed  {
  constructor(milliseconds) {

    this.total = milliseconds
    if (milliseconds === null) return

    let rem = milliseconds
    this.years = Math.floor(rem / 31536000000)
    rem %= 31536000000
    this.days = Math.floor(rem / 86400000)
    rem %= 86400000
    this.hours = Math.floor(rem / 3600000)
    rem %= 3600000
    this.minutes = Math.floor(rem / 60000)
    rem %= 60000
    this.seconds = Math.floor(rem / 1000)
    rem %= 1000
    this.milliseconds = rem
  }

  to_array() {
    if (this.total === null) throw new Error("APIElapsed is null.")
    return [this.milliseconds, this.seconds, this.minutes, this.hours, this.days, this.years]
  }

  to_user(resol=2) {
    if (this.total === null) return ""

    const values = this.to_array()
    const names = ['milisegundo', 'segundo', 'minuto', 'hora', 'día', 'año']

    let r = ""
    let start = null
    for (let i = 5; i >= 0 && resol; i--) {
      if (values[i]) {
        if (r) r += ', '
        r += values[i].toString() + ' ' + names[i] + (values[i] != 1 ? 's' : '')
        if (!start) start = i
      }
      if (start != null) {
        resol--
      }
    }

    return r
  }
}

export function prettify_phone(s) {
  if (!s) return ""
  const stripped = s.replace(/\s/g, '')
  if (stripped.length == 9) {
    return stripped[0] + stripped[1] + stripped[2] + ' ' + stripped[3] + stripped[4] + ' ' + stripped[5] + stripped[6] + ' ' + stripped[7] + stripped[8]
  }
  else if (stripped.length == 12 && stripped[0] == '+') {
    return stripped[0] + stripped[1] + stripped[2] + '  ' + stripped[3] + stripped[4] + stripped[5] + ' ' + stripped[6] + stripped[7] + ' ' + stripped[8] + stripped[9] + ' ' + stripped[10] + stripped[11]
  }

  return s
}

export function canonify_phone(s) {
  return s.replace(/\s/g, '')
}