import { createRouter, createWebHistory, isNavigationFailure } from 'vue-router'
import { is_authenticated } from '@/API/api.js'
import { store } from '../store'
import HomeView from '../views/HomeView.vue'
import LoggedPage from '../views/LoggedPage.vue'
import E404Page from '../views/404Page.vue'



const routes = [
  {
    path: '/',
    component: LoggedPage,
    children: [
      {
        path: '',
        children: [
          {
            path: '',
            name: 'welcome',
            component: HomeView
          }
        ]
      },
      {
        path: 'alumnos',
        children: [
          {
            path: '',
            name: 'alumnos',
            component: () => import('../views/AlumnosView.vue')
          },
          {
            path: 'informe-asistencia',
            name: 'informe-asistencia',
            component: () => import('../views/AttendanceReportView.vue')
          }
        ]
      },
      {
        path: 'matriculacion',
        children: [
          {
            path: '',
            name: 'matriculacion',
            component: () => import('../views/MatriculacionView.vue')
          },
          {
            path: 'nueva',
            name: 'matriculacion-nueva',
            component: () => import('../views/MatriculacionNewView.vue')
          },
          {
            path: 'revisar',
            name: 'matriculacion-revisar',
            component: () => import('../views/MatriculacionNew2View.vue')
          },
          {
            path: 'cancelar',
            name: 'matriculacion-cancelar',
            component: () => import('../views/MatriculacionCancelView.vue')
          },
          {
            path: 'bono-particulares',
            name: 'matriculacion-bono-particulares',
            component: () => import('../views/MatriculacionBonoParticulares.vue')
          }
        ]
      },
      {
        path: 'cobros',
        children: [
          {
            path: '',
            name: 'cobros',
            component: () => import('../views/CobrosView.vue')
          },
        ]
      },
      {
        path: 'lista-recibos-pago',
        name: 'lista-recibos-pago',
        component: () => import('../views/ListaCobrosView.vue')
      },
      {
        path: 'impagos',
        name: 'impagos',
        component: () => import('../views/ImpagosView.vue')
      },
      {
        path: 'registro-particulares',
        name: 'registro-particulares',
        component: () => import('../views/RegistroParticularesView.vue')
      },
      {
        path: 'contacto',
        children: [
          {
            path: ':id?',
            name: 'contacto',
            component: () => import('../views/ContactoView.vue'),
          },
          {
            path: 'nueva',
            name: 'contacto-nueva',
            component: () => import('../views/ContactoNewView.vue')
          }
        ]
      },
      {
        path: 'editor-cursos',
        name: 'editor-cursos',
        component: () => import('../views/EditorCursos/EditorCursosView.vue')
      },
      {
        path: 'informes',
        name: 'informes',
        component: () => import('../views/InformesView.vue')
      },
      {
        path: 'avanzado',
        name: 'avanzado',
        component: () => import('../views/AvanzadoView.vue')
      },
      {
        path: 'testing',
        name: 'testing',
        component: () => import('../views/TestingView.vue')
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginPage.vue')
  },
  {
    path: '/404',
    name: '404',
    component: E404Page
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Do not scroll to top if we are rewriting the url
    if (store.no_scroll_to_top) {
      store.no_scroll_to_top = false 
      return
    }

    // This seems to be a sane scrolling behavior
    // Copied from SO (why isn't this the default?)
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        selector: to.hash
      }
    } else {
      return { left: 0, top: 0 }
    }
  },
})



router.beforeEach(function(to, from) {
  // Form data for multipage forms
  store.form_data.data = store.form_data.next_page_data
  store.form_data.next_page_data = {}
  store.form_data.prev = from.name
  
  if (!to.name) {
    return { name: '404' }
  }

  if (to.name !== 'login' && !is_authenticated()) {
    store.redirect_to_after_login = to
    return { name: 'login' }
  }
  else if (to.name === 'login' && is_authenticated()) {
    return { name: 'home' }
  }
})

export default router
