// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import colors from 'vuetify/lib/util/colors'
import { VCard } from 'vuetify/lib/components'

// Vuetify
import { createVuetify } from 'vuetify'
import { VTextField, VSelect, VCombobox, VBtn, VSwitch, VTextarea } from 'vuetify/lib/components'

export default createVuetify({
  aliases: {
    OutterCard: VCard,
    InnerCard: VCard,

    TextField: VTextField,
    TextArea: VTextarea,
    SelectField: VSelect,
    ComboBox: VCombobox,
    SwitchBtn: VSwitch,

    ButtonSecondary: VBtn,
    ButtonPrimary: VBtn,
    ButtonRound: VBtn,
    ButtonRoundDanger: VBtn,
    
  },

  defaults: {
    OutterCard: {
      class: "mb-4",
      //variant: "tonal",
      //flat: true,
      //color: "blue"
    },

    TextField: {
      color: "primary",
      variant: "outlined",
      density: "comfortable"
    },

    TextArea: {
      color: "primary",
      variant: "outlined",
      density: "comfortable"
    },

    SelectField: {
      color: "primary",
      variant: "outlined",
      density: "comfortable"
    },

    ComboBox: {
      color: "primary",
      variant: "outlined",
      density: "comfortable"
    },

    SwitchBtn: {
      density: "comfortable",
      color: "primary"
    },

    ButtonSecondary: {
      variant: 'flat',
      color: 'secondary'
    },

    ButtonPrimary: {

    },

    ButtonRound: {
      variant: "flat", 
      color: "secondary", 
      density: "compact",
      class: "mr-2",
    },

    ButtonRoundDanger: {
      variant: "flat", 
      color: "error", 
      density: "compact",
      class: "mr-2",
    }


  },

  theme: {
    defaultTheme: 'outbox',
    themes: {
      outbox: {
        dark: false,
        colors: {
          app_bar: colors.indigo.darken4,
          primary: colors.indigo.darken4,
          surface: '#fff',
          background: colors.grey.lighten4,
          secondary: colors.blue.lighten4, //colors.lime.base,
          error: colors.red.darken1,
          warning: colors.orange.darken1,
          info: colors.blue.base,
          success: colors.green.base,
          page_title: colors.grey.darken4,
          text_red: colors.red.darken4,
          text_blue: colors.blue.base,
          text_green: colors.green.base,
          text_grey: colors.grey.base
        }
      },

      green: {
        dark: false,
        colors: {
          app_bar: '#135E18',
          primary: '#135E18',
          surface: '#fff',
          background: colors.grey.lighten4,
          secondary: '#B0F086',
          error: colors.red.darken1,
          warning: colors.orange.darken1,
          info: colors.blue.base,
          success: colors.green.base,
          page_title: colors.grey.darken4,
          text_red: colors.red.darken4,
          text_blue: colors.blue.base,
          text_green: colors.green.base,
          text_grey: colors.grey.base
        }
      },

      lavender: {
        dark: false,
        colors: {
          app_bar: '#43267E',
          primary: '#43267E',
          surface: '#fff',
          background: colors.grey.lighten4,
          secondary: '#BEC6FB',
          error: colors.red.darken1,
          warning: colors.orange.darken1,
          info: colors.blue.base,
          success: colors.green.base,
          page_title: colors.grey.darken4,
          text_red: colors.red.darken4,
          text_blue: colors.blue.base,
          text_green: colors.green.base,
          text_grey: colors.grey.base
        }
      },

      outbox_dark: {
        dark: true,
        colors: {
          app_bar: '#000',
          primary: '#CAF279',
          surface: '#181818',
          background: '#222',
          secondary: '#307EDD',
          error: colors.red.lighten1,
          warning: colors.orange.darken1,
          info: colors.blue.base,
          success: colors.green.base,
          page_title: '#FFF',
          text_red: '#B54A4A',
          text_blue: '#3DB5FF',
          text_green: '#6E9953',
          text_grey: colors.grey.base
        }
      },
    },
    variations: {
      colors: ['primary'],
      lighten: 2,
      darken: 2,
    },
  }
}
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
)
