<template>
  <v-container class="pa-0 payment-list">
    <app-dialog 
      :cancelbutton="cancelbutton" 
      :canceltext="canceltext"
      :oktext="oktext"
      :footnote="null"
      :width="width"
      v-model="show_dlg" 
      :title="title"
      :titledetail="titledetail"
      :persistent="persistent"
      @close="close"
      :capturekeyevent="true"
      ref="msgbox">
        <slot></slot>
    </app-dialog>
  </v-container>
</template>



<script>
import AppDialog from '@/components/AppDialog.vue'
import { watch, ref } from 'vue'

const close_status = ref(0)

export default {

  props: [ 'title', 'titledetail', 'oktext', 'canceltext', 'cancelbutton', 'persistent', 'width' ],
  emits: [ ],


  components: {
    AppDialog,
  },

  data() {
    return {
      show_dlg: false,
      promise: null,
      unwatch: null,

      external_value: null,
    }
  },

  methods: {

    async show(cancel_close = null) {
      close_status.value = 0
      this.external_value = null
      this.show_dlg = true
      return new Promise(resolve => {
        this.unwatch = watch(close_status, async (n, o) => {
          if (!close_status.value) return
          if (cancel_close && cancel_close(
              close_status.value == 3 ? this.external_value : close_status.value - 1
          )) {
            // Cancel close
            close_status.value = 0
            this.$refs.msgbox.vibrate()
            this.$refs.msgbox.cancelclose()
            return
          }
          if (this.unwatch) this.unwatch()
          this.unwatch = null
          this.show_dlg = false
          resolve(close_status.value == 3 ? this.external_value : close_status.value - 1)
        })
      })
    },

    close(ok) {
      if (ok) close_status.value = 1
      else close_status.value = 2
    },

    close_ext(value) {
      this.external_value = value
      close_status.value = 3
    }

  }

}
</script> 