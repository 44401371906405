<template>
  <nav-bar />
  <v-main class="mx-4 mb-4">
    <v-container fluid>
      <router-view></router-view>
    </v-container>
  </v-main>
  <info-msg />
</template>

<script>
import NavBar from '@/components/NavBar'
import InfoMsg from '@/components/InfoMsg.vue'

export default {

  components: {
    NavBar,
    InfoMsg
  },

  data: () => ({
    //
  }),
}
</script>
