// New datatypes are created by annotating old ones using .$
// For example type.$({null: true}) is a new datatype derived from type
// which allows nullable values.
// Basic type checking can be done via .superclass_of, for example
// type.superclass_of(type.$({null: true})) is true
//
// Caveat: each time .$ is called a new derived type is created, therefore
// type.$({null: true}.superclass_of(type.$({null: true})) is false
// Never test against annotated types!!
// [Is there any way to fix this?]


export class DRFDatatype {
  constructor(value) {
    this._value = value

    if (!this.is_nullable() && this._value === null) throw new Error("Non nullable member is null.")
  }

  toString() {
    if (this._value === null) return ""

    const text = this.to_user()

    if (!text) return ""

    const display_prefix = this.get_annotations().display_prefix || ''
    const display_prefix_space = this.get_annotations().display_prefix_space || ''
    const display_suffix = this.get_annotations().display_suffix || ''
    const display_suffix_space = this.get_annotations().display_suffix_space || ''

    return display_prefix + display_prefix_space + text + display_suffix_space + display_suffix
  }

  to_user() {
    return this._value.toString()
  }

  to_user_class() {
    if (this.get_annotations().class) return this.get_annotations().class
    return []
  }

  to_server(query = false) {
    if (this._value === null && query) return ""
    if (this._value === null && !query) return null
    return this._value.toString()
  }

  valueOf() {
    return this._value
  }

  [Symbol.toPrimitive](hint) {
    if (hint === 'number') {
      return this.valueOf()
    }

    return this.toString()
  }

  get_annotations() {
    return this.get_type().annotations
  }

  get_type() {
    return this.constructor
  }

  clone(v = undefined) {
    return (new this.get_type())(v === undefined ? this._value : v)
  }

  is_nullable() {
    return this.get_annotations().null
  }

  is_null() {
    return this._value === null
  }

  compare(other) {
    if (this._value === null && other?._value === null) return 0
    if (this._value === null || other?._value === null || other?._value === undefined) return undefined
    if (this._value == other._value) return 0
    else if (this._value < other._value) return -1
    return 1
  }

  equal(other) {
    return this.compare(other) == 0
  }

  lt(other) {
    return this.compare(other) == -1
  }

  lte(other) {
    return this.compare(other) <= 0
  }

  gt(other) {
    return this.compare(other) == +1
  }

  gte(other) {
    return this.compare(other) >= 0
  }

  static from_server(value) {
    return new this(value)
  }

  static get_annotations() {
    return this.annotations
  }

  static is_nullable() {
    return this.annotations.null
  }

  static $(annotations = {}) {
    // Create a new data type with the corresponding annotations
    const Current = this

    class Annotated extends Current {}
    Annotated.annotations = {...Current.annotations, ...annotations}

    return Annotated
  }

  static superclass_of(type) {
    if (!type) return false
    return type.prototype instanceof this || this === type
  }

  static subclass_of(type) {
    return this.prototype instanceof type || this === type
  }

  static null_value() {
    return new (this.$({null: true}))(null)
  }
}
DRFDatatype.annotations = {null: false}


export class DRFString extends DRFDatatype {
  constructor(value) {
    super(value !== null ? value.toString() : null)
  }

  to_server(query = false) {
    // Strings usually are only nullable if they are set to Unique
    // In case of nullable, avoid empty strings
    if (this._value === '' && this.is_nullable() && this.if_nullable_no_empty()) {
      return this.clone(null).to_server(query)
    }

    return super.to_server(query)
  }

  is_blank() {
    return this._value === ''
  }

  if_nullable_no_empty() {
    return this.get_annotations().if_nullable_no_empty ?? true
  }

  static empty_value() {
    return new this('')
  }
}


export class DRFBoolean extends DRFDatatype {
  constructor(value) {
    super(value !== null ? !!value : null)
  }

  to_user() {
    if (this._value === null) return super.to_user()
    if (this._value) return "Sí"
    return "No"
  }

  static from_server(value) {
    if (value == 'true' || value == 'false') return super.from_server(value == 'true')
    else if (value === true || value === false) return super.from_server(value)
    else if (value === null) return super.from_server(null)
    throw new Error("Not boolean.")
  }

  static false() {
    return new this(false)
  }

  static true() {
    return new this(true)
  }
}


export class DRFInteger extends DRFDatatype.$({column_class: ['text-right']}) {
  constructor(value) {
    super(value !== null ? parseInt(value) : null)
  }

  to_server(query = false) {
    if (!this._value) return super.to_server(query)
    return this._value
  }

  compare(other) {
    if (typeof other == 'number') other = new (this.get_type())(other)
    return super.compare(other)
  }

  static zero() {
    return new this(0)
  }

  static from_integer(v) {
    return new this(v)
  }
}


export class DRFFloat extends DRFDatatype.$({column_class: ['text-right']}) {
  constructor(value) {
    super(value !== null ? parseFloat(value) : null)
  }

  compare(other) {
    if (typeof other == 'number') other = new (this.get_type())(other)
    return super.compare(other)
  }

  static zero() {
    return new this(0)
  }
}


export class DRFDecimal extends DRFDatatype.$({column_class: ['text-right']}) {
  // Value is an integer representing the decimal number (or null)
  constructor(value) {
    if (value !== null && !Number.isInteger(value)) throw new Error("Internal representation must be an integer.")

    super(value)

    if (!('decimal_places' in this.get_annotations())) throw new Error("Specify the number of decimal places.")
  }

  to_user() {
    return this.to_float().toFixed(this.decimal_places()).replace('.', ',')
  }

  to_server(query = false) {
    if (this._value === null) return super.to_server(query)
    return this.to_float().toFixed(this.decimal_places())
  }

  to_float() {
    if (this._value === null) return null
    return this._value/(10 ** this.decimal_places())
  }

  decimal_places() {
    return this.get_annotations().decimal_places
  }

  compare(other) {
    if (typeof other == 'number') other = new (this.get_type())(other * (10 ** this.get_annotations().decimal_places))
    // TODO: Compare when decimal_places are different
    return super.compare(other)
  }

  static from_server(value) {
    if (!('decimal_places' in this.get_annotations())) throw new Error("Specify the number of decimal places.")
    
    return super.from_server(
      value !== null ? Math.round(parseFloat(value) * (10 ** this.get_annotations().decimal_places)) : null
    )
  }

  static zero() {
    return new this(0)
  }

  static from_float(v) {
    return new this(Math.round(v * (10 ** this.annotations.decimal_places)))
  }
}


export class DRFDateTime extends DRFDatatype {
  // Value is a Date object (or null)
  constructor(value) {
    if (value !== null && !(value instanceof Date)) throw new Error("Not date.")

    super(value)
  }

  to_user() {
    let r = ""
    
    r += this._value.getDate().toString().padStart(2, '0') 
      + '/' + (this._value.getMonth() + 1).toString().padStart(2, '0')
      + '/' + this._value.getFullYear().toString().padStart(4, '0')

    r += ' '
    r += this._value.getHours().toString().padStart(2, '0')
      + ':' + this._value.getMinutes().toString().padStart(2, '0')
      + (this.display_seconds() ? ':' + this._value.getSeconds().toString().padStart(2, '0') : '')

    return r
  }

  to_server(query = false) {
    if (this._value === null) return super.to_server(query)
    return this._value.toISOString().slice(0,19)
  }

  display_seconds() {
    return this.get_annotations().display_seconds || false
  }

  compare(other) {
    if (other instanceof Date) other = new (this.get_type())(other)
    if (this._value == null || other?._value == null || other?._value == undefined) return super.compare(other)
    const this_value = this._value.getTime()
    const other_value = other._value.getTime()
    if (this_value == other_value) return 0
    else if (this_value < other_value) return -1
    return 1
  }

  static from_server(value) {
    if (value && (!value.includes('-') || !value.includes(':'))) throw new Error("Not datetime.")
    return super.from_server(value ? new Date(value) : null)
  }

  static now() {
    return new this(new Date())
  }

  elapsed(otherDate = undefined) {
    if (otherDate === undefined) otherDate = new Date()
    if (!this._value || !otherDate) return DRFDuration.null_value()
    if (otherDate instanceof DRFDatatype) otherDate = otherDate.value
    
    const nullable = this.is_nullable() || !otherDate
    const type = nullable ? DRFDuration.$({null: true}) : DRFDuration
    return new type(Math.abs(this._value.getTime() - otherDate.getTime()))
  }
}


export class DRFDate extends DRFDatatype {
  // Value is a Date object (or null)
  constructor(value) {
    if (value !== null && !(value instanceof Date)) throw new Error("Not date.")
    if (value) {
      value.setUTCHours(0)
      value.setUTCMinutes(0)
      value.setUTCSeconds(0)
      value.setUTCMilliseconds(0)
    }

    super(value)
  }

  to_user() {
    let r = ""
    
    r += this._value.getDate().toString().padStart(2, '0') 
      + '/' + (this._value.getMonth() + 1).toString().padStart(2, '0')
      + '/' + this._value.getFullYear().toString().padStart(4, '0')

    return r
  }

  to_server(query = false) {
    if (this._value === null) return super.to_server(query)
    return this._value.toISOString().slice(0,10)
  }

  compare(other) {
    if (other instanceof Date) other = new (this.get_type())(other)
    if (this._value == null || other?._value == null || other?._value == undefined) return super.compare(other)
    const this_value = this._value.getTime()
    const other_value = other._value.getTime()
    if (this_value == other_value) return 0
    else if (this_value < other_value) return -1
    return 1
  }

  static from_server(value) {
    if (value && (!value.includes('-') || value.includes(':'))) throw new Error("Not date.")
    return super.from_server(value ? new Date(value) : null)
  }

  static now() {
    return new this(new Date())
  }
}


export class DRFTime extends DRFDatatype {
  // Value is a Date object (or null)
  constructor(value) {
    if (value !== null && !(value instanceof Date)) throw new Error("Not date.")
    if (value) {
      value.setUTCFullYear(1970)
      value.setUTCMonth(0)
      value.setUTCDate(1)
    }

    super(value)
  }

  to_user() {
    let r = ""
    
    r += this._value.getHours().toString().padStart(2, '0')
      + ':' + this._value.getMinutes().toString().padStart(2, '0')
      + (this.display_seconds() ? ':' + this._value.getSeconds().toString().padStart(2, '0') : '')

    return r
  }

  to_server(query = false) {
    if (this._value === null) return super.to_server(query)
    return this._value.toISOString().slice(11,19)
  }

  display_seconds() {
    return this.get_annotations().display_seconds || false
  }

  compare(other) {
    if (other instanceof Date) other = new (this.get_type())(other)
    if (this._value == null || other?._value == null || other?._value == undefined) return super.compare(other)
    const this_value = this._value.getTime()
    const other_value = other._value.getTime()
    if (this_value == other_value) return 0
    else if (this_value < other_value) return -1
    return 1
  }

  static from_server(value) {
    if (value && (value.includes('-') || !value.includes(':'))) throw new Error("Not time.")
    return super.from_server(value ? new Date('1970-01-01T' + value) : null)
  }

  static now() {
    return new this(new Date())
  }
}


export class DRFDuration extends DRFDatatype {
  // For now value is just an integer as number of elapsed microseconds
  constructor(value) {
    if (value !== null && (!Number.isInteger(value) || value < 0)) throw new Error("Not a duration.")

    super(value)
  }

  to_user() {
    let rem = this._value
    const years = Math.floor(rem / 31536000000)
    rem %= 31536000000
    const days = Math.floor(rem / 86400000)
    rem %= 86400000
    const hours = Math.floor(rem / 3600000)
    rem %= 3600000
    const minutes = Math.floor(rem / 60000)
    rem %= 60000
    const seconds = Math.floor(rem / 1000)
    rem %= 1000
    const milliseconds = rem

    const array = [milliseconds, seconds, minutes, hours, days, years]

    let resol = this.get_annotations().display_resolution || 2

    const names = ['milisegundo', 'segundo', 'minuto', 'hora', 'día', 'año']

    let r = ""
    let start = null
    for (let i = 5; i >= 0 && resol; i--) {
      if (array[i]) {
        if (r) r += ', '
        r += array[i].toString() + ' ' + names[i] + (array[i] != 1 ? 's' : '')
        if (!start) start = i
      }
      if (start != null) {
        resol--
      }
    }

    return r
  }


  to_server(query = false) {
    throw new Error("Not implemented.")
  }

  static from_server(value, annotations) {
    throw new Error("Not implemented")
  }
}


export class DRFEnum extends DRFDatatype {
  constructor(value) {
    super(value)

    if (!this.get_annotations().source) throw new Error("Undefined source for DRFEnum.")
    this._source = this.get_annotations().source

    this._check_value()
  }

  to_user() {
    if (this._value === null) return super.to_user()
    return this.get_enum().get_data(this).title
  }

  to_server(query = false) {
    this._check_value()
    return super.to_server(query)
  }

  get_enum() {
    return this.get_type().get_enum()
  }

  _check_value() {
    if (this._value !== null && !this.get_enum().get_data(this)) throw new Error("Invalid value.")
  }

  static set_api(api) {
    DRFEnum.api = api
  }

  static get_api() {
    if (DRFEnum.api) return this.api
    if (this.annotations.api) return this.annotations.api
    return null
  }

  static default() {
    if (!('source' in this.annotations)) throw new Error("Undefined source for DRFEnum.")
    const _enum = this.get_enum()

    return _enum.get_default(this)
  }

  static get_enum() {
    const api = this.get_api()
    const source = this.annotations.source

    if (!source) throw new Error("Undefined source for DRFEnum.")
    
    if (typeof source == 'string') {
      if (!api) throw new Error("DRFEnum needs an API object to lookup the possible values.")

      const splitted = source.split('.')
      if (splitted.length != 2) throw new Error("Invalid DRFEnum source: " + source + ".")

      if (!(splitted[0] in api)) throw new Error("Invalid DRFEnum source: " + source + ".")

      const endpoint = api[splitted[0]]

      if (!(splitted[1] in endpoint)) throw new Error("Invalid DRFEnum source: " + source + ".")

      return endpoint[splitted[1]]
    }
    else {
      return source
    }
  }
}

export class DRFObject extends DRFDatatype {
  constructor(value) {
    // ID, this class is only a reference to an object
    // Or a whole object with an id field

    let preloaded_obj = null
    if (value instanceof DRFObject) value = value.to_plain_object()
    if (value !== null && value !== undefined && value.constructor === Object && value.id !== undefined) {
      preloaded_obj = value
      value = value.id
    }

    if (value !== null && !(Number.isInteger(value) && value > 0)) throw new Error("Not an object.")

    super(value)
    this._loading = null
    this._error = ""
    this._self = null
    this.id = this._value

    if (!this.get_annotations().resource) throw new Error("Undefined resource for DRFObject.")

    if (preloaded_obj) {
      this.attach_object(preloaded_obj)
    }
  }

  to_user() {
    if (!this._loading && !this._error && !this._obj
        && this.get_annotations().auto_fetch !== false) {
      this.fetch()
    }

    if (this._error) return this._error
    if (this._loading) return "···"

    if (this._obj) {
      const endpoint = this.api_endpoint()
      if (endpoint) return endpoint.title(this._self ? this._obj : this)
    }

    return super.to_user()
  }

  to_user_class() {
    let c = super.to_user_class()

    if (this._error) {
      const api = this.get_type().get_api()
      if (api?.display?.object_load_error_classes) {
        c = c.concat(api.display.object_load_error_classes)
      }
    }

    return c
  }

  to_server(query = false) {
    // DRFObject instances always serialize to their id
    // If you want the whole object, for example for updating or creating
    // instances, use the .to_plain_object() method
    return super.to_server(query)
  }

  attach_object(obj, assign_self = true, make_id_plain = true) {
    if (!obj) this._obj = obj
    if (!(typeof obj == 'object') || !('id' in obj)) throw new Error("Not an object.")
    if (+obj.id != this._value) throw new Error("Not the same object.")

    this._obj = obj
    this._self = assign_self
    if (assign_self) {
      Object.assign(this, obj)
      if (make_id_plain) this.id = +this.id
    }
  }

  has_object() {
    return !!this._obj
  }

  get_object() {
    return this._obj
  }

  get_error() {
    return this._error
  }

  to_plain_object() {
    let {...object} = this
    delete object._error
    delete object._obj
    delete object._loading
    delete object._value
    delete object._self
    return object
  }

  api_endpoint() {
    const api = this.get_type().get_api()
    if (!api) return undefined
    return api[this.get_annotations().resource]
  }

  async fetch() {
    if (this._loading) await this._loading
    if (this._self) return this
    if (this._obj) return this._obj
    return await this.refresh()
  }

  async refresh() {
    const endpoint = this.api_endpoint()
    if (!endpoint) throw new Error("Assign an API object to load the object.")
    if (!this._value) return null

    this._error = ""

    try {
      this._loading  = endpoint.get(this._value, {}, null)
      const obj = await this._loading 
      this.attach_object(obj)
      if (this._self) return this
      return this._obj
    }
    catch(e) {
      console.log(e)
      this._error = "ERROR(" + this._value.toString() + ')'
    }
    finally { this._loading = null }
  }

  static async fetch_related(obj) {
    let to_be_fetched = []
    this._fetch_related_walker(obj, to_be_fetched)
    return await Promise.allSettled(to_be_fetched)
  }

  static _fetch_related_walker(obj, list) {
    if (!obj) return

    if (obj instanceof DRFObject) {
      list.push(obj.fetch())
    }

    if (Array.isArray(obj)) obj.map(x => this._fetch_related_walker(x, list))

    if (obj.constructor === Object) {
      for (const key in obj) this._fetch_related_walker(obj[key], list)
    }
  }

  static set_api(api) {
    DRFObject.api = api
  }

  static get_api() {
    if (DRFObject.api) return this.api
    if (this.annotations.api) return this.annotations.api
    return null
  }
}


export class IdentityObjectToServer {
  constructor() { }

  parse(v, query) {
    return v
  }
}

export class DRFObjectToServer {
  constructor() { }

  parse(v, query) {
    if (query) return this.object_to_query(v)
    else return this.object_to_body(v)
  }

  object_to_body(v) {
    if (v === null || v === undefined) return DRFDatatype.null_value().to_server(false)

    if (v instanceof DRFDatatype) {
      return v.to_server(false)
    }
  
    if (Array.isArray(v)) {
      return v.map(x => this.object_to_body(x))
    }
    if (v.constructor === Object) {
      let new_obj = {}
  
      Object.keys(v).forEach(key => {
        new_obj[key] = this.object_to_body(v[key])
      })
  
      return new_obj
    }
  
    return v
  }

  object_to_query(v) {
    if (v === null || v === undefined) return DRFDatatype.null_value().to_server(true)

    if (v instanceof DRFDatatype) {
      return v.to_server(true)
    }
  
    if (Array.isArray(v)) {
      let array = v.map(x => this.object_to_query(x))
      if (!v.serialize_as || v.serialize_as == 'default') return array
      else if (v.serialize_as == 'csv') return array.join(',')
      else throw new Error("Invalid serialize_as value.")
      // 'multiple' serialization is done at the parent level (below)
    }
    if (v.constructor === Object) {
      let multiple_params = false

      // Check if any array is set to be serialized as multiple params
      for (const key in v) {
        if (Array.isArray(v[key]) && v[key].serialize_as == 'multiple') {
          multiple_params = true
          break
        }
      }

      if (multiple_params) {
        // Serialize as URLSearchParams with repeated args
        let new_obj = new URLSearchParams()

        for (const key in v) {
          if (Array.isArray(v[key]) && v[key].serialize_as == 'multiple') {
            for (const x of v[key]) new_obj.append(key, this.object_to_query(x))
          }
          else new_obj.append(key, this.object_to_query(v[key]))
        }

        return new_obj
      }
      else {
        // Serialize as plain object
        let new_obj = {}

        for (const key in v) {
          new_obj[key] = this.object_to_query(v[key])
        }

        return new_obj
      }
    }
    if (v instanceof URLSearchParams) {
      let new_obj = new URLSearchParams()

      for (const [key, value] of v) {
        new_obj.append(key, this.object_to_query(value))
      }

      return new_obj      
    }
  
    return v.toString()
  }
}

export class IdentityResponseToObject {
  constructor() { }

  parse(v) { 
    return v
  }
}

export class DRFResponseToObject {
  constructor(schema) {
    this.schema = schema
  }

  parse(v) {
    try {
      return this._response_to_object(v, this.schema)
    }
    catch(e) {
      e.response = v
      throw e
    }
  }

  _response_to_object(v, schema) {
    if (schema === null || schema === undefined) return v

    if (DRFDatatype.superclass_of(schema)) {
      if (v !== null && v !== undefined && DRFObject.superclass_of(schema) && schema.annotations.resource && schema.get_api() && v.constructor === Object) {
        let obj_schema = schema.get_api()[schema.annotations.resource]?.schema
        if (obj_schema) {
          let parser = new DRFResponseToObject(obj_schema)
          v = parser.parse(v)
        }
      }

      return schema.from_server(v)
    }

    if (Array.isArray(schema)) {
      if (!Array.isArray(v)) return v

      try {
        return v.map(x => this._response_to_object(x, schema[0]))
      }
      catch(e) {
        if (e instanceof Error) {
          e.message += ' > []'
        }
        throw e
      }
    }

    if (schema.constructor === Object) {
      let new_obj = {}

      for (const key in v) {
        if (key in schema) {
          try {
            new_obj[key] = this._response_to_object(v[key], schema[key])
          }
          catch(e) {
            if (e instanceof Error) {
              e.message += ' > {.' + key + '}'
            }
            throw e
          }
        }
        else {
          new_obj[key] = v[key]
        }
      }

      // If it is a DRFObject, encapsulate it
      // We will keep id as a plain field, as it is often used internally
      // to compare objects, and the whole object now carries all the
      // necessary information
      if ('id' in new_obj && new_obj.id instanceof DRFObject) {
        const type = new_obj.id.get_type()
        const value = new_obj.id.valueOf()
        let encapsulated_obj = new type(value)
        encapsulated_obj.attach_object(new_obj)
        return encapsulated_obj
      }

      return new_obj
    }

    return v
  }
}